import React from "react";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
} from "semantic-ui-react";
import footerStyle from "./Footer.module.scss";
import logo from "../../assets/images/logo.jpg";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";

import edLogo from "../../assets/images/edlogo.png";
function Footer() {
  return (
    <div>
      <Menu secondary fixed="bottom" className={footerStyle.footerStyle}>
        <div style={{ height: "100%", width: "120px" }}>
          <Image
            size="small"
            src={`https://${
              Constants.DOMAIN
            }/${config.aws_org_id.toLocaleLowerCase()}-resources/images/org-images/logo.jpg`}
            style={{
              marginRight: "1.5em",
              height: "100%",
              width: "100%",
            }}
          />
        </div>
        <Menu.Item
          position="right"
          className="sidebar-btn-wrapper"
          style={{
            padding: "8px 24px",
            float: "right",
          }}
        >
          <a
            href="https://enhanzed.com/"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <div className="legal" style={{ fontSize: "14px" }}>
              &copy; 2024 Powered by <img src={edLogo} alt="" />
            </div>
          </a>
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default Footer;
