import React, { useEffect, useState, useRef } from "react";
import {
  Tab,
  Container,
  Form,
  Header,
  Image,
  Radio,
  TextArea,
} from "semantic-ui-react";
import { FaBars } from "react-icons/fa";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import PersonIcon from '@material-ui/icons/Person';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import UserHeader from "../Header/UserHeader/UserHeader";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { API } from "aws-amplify";
import axios from "axios";
import ApplicationStyle from "./Application.module.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import edLogo from "../../assets/images/profilep.png";
import Backdrop from "@material-ui/core/Backdrop";
import "./applicationform.css";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
    marginLeft: "20%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Application({ handleToggleSidebar }) {
  
  const refselectedCourseFile = useRef();
 
  const [spin, setSpin] = useState(false);
  const [h, H] = useState(false);

  /*  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [midleName, setMidleName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState({ ccode: 0, telephone: 0 });
  const [gender, setGender] = useState("Male");
  const [dob, setDob] = useState("");
  const [parents, setParents] = useState({ fathername: "", mothername: "" });
  const [internationalStu, setInternationalStu] = useState("Yes");
  const [passport, setPassport] = useState(0);
  const [otherGovt, setOtherGovt] = useState(null);
  const [detailsid, setDetailsid] = useState("");
  const [perAddr, setPerAddr] = useState({
    addr1: "",
    addr2: "",
    addr3: "",
    state: "",
    city: "",
    pin: "",
  });
  const [presAddr, setPresAddr] = useState({
    addr1: "",
    addr2: "",
    addr3: "",
    state: "",
    city: "",
    pin: "",
  });
  const [sameAddr, setSameAddr] = useState(false);
  const [pastNationality, setPastNationality] = useState("");
  const [h, H] = useState(false);
  const [uploadDisable, setUploadDisable] = useState(false);
  const [scholarshipDisable, setScholarshipDisable] = useState(false);
  const [reviewDisable, setReviewDisable] = useState(false);
  const [issueText, setIssueText] = useState("");
  const [issueTextEducation, setIssueTextEducation] = useState("");
  const [issueTextUpload, setIssueTextUpload] = useState("");

  //Issuetexts

  const [nameIsseText, setNameIssueText] = useState("");
  const [emailIsseText, setEmailIssueText] = useState("");
  const [phoneIsseText, setPhoneIssueText] = useState("");
  const [dobIsseText, setDOBIssueText] = useState("");
  const [parentsIsseText, setParentsIssueText] = useState("");
  const [govtIsseText, setGovtIssueText] = useState("");
  const [addressIsseText, setAddressIssueText] = useState("");

  //Education variables declaration

  const [schoolName, setSchoolName] = useState("");
  const [schAddr, setSchAddr] = useState({
    addr1: "",
    addr2: "",
    addr3: "",
    country: "",
    zip: "",
  });
  const [university, setUniversity] = useState("");
  const [uniAddr, setUniAddr] = useState({
    addr1: "",
    addr2: "",
    addr3: "",
    country: "",
    zip: "",
  });
  const [edType, setEdType] = useState("Graduation");
  const [specialization, setSpecialization] = useState("Science");
  const [experiance, setExperiance] = useState("");
  const [experianceDesc, setExperianceDesc] = useState("");

  //Scholarship

  const [scholarship, setScholarship] = useState("");
  const [learningCenter, setLearningCenter] = useState("");

  //files

  const [certificate, setCertificate] = useState(null);
  const [transcripts, setTranscripts] = useState(null);
  const [passportFile, setPassportFile] = useState(null);
  const [otherGovtFile, setOtherGovtFile] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);

  const [certificateN, setCertificateN] = useState("");
  const [transcriptsN, setTranscriptsN] = useState("");
  const [passportFileN, setPassportFileN] = useState("");
  const [otherGovtFileN, setOtherGovtFileN] = useState("");
  const [profilePhotoN, setProfilePhotoN] = useState(""); */
  const [nameIsseText, setNameIssueText] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [sissueTextUpload, setSIssueTextUpload] = useState("");
  const [pissueTextUpload, setPIssueTextUpload] = useState("");

  let navigate = useNavigate();
  const dispatch = useDispatch();
  let userDetails = useSelector(authData);
  const [application, setApplication] = useState({
    appTitle: "",
    firstName: "",
    maidenName: "",
    surName: "",
    email: userDetails.email,
    phoneNo: "",
    age: "0",
    dob: "",
    nationality: "",
    nic: "",
    passport: "",
    address: "",
    programmeTable: [
      { title: "", ftime: "", ptime: "" },
    ],
    certificate: {
      tenthYear: "",
      tenthRegistration: "",
      tenth: [
        { Subjects: "", tenthGrades: "" },
        { Subjects: "", tenthGrades: "" },
        { Subjects: "", tenthGrades: "" },
        { Subjects: "", tenthGrades: "" },
        { Subjects: "", tenthGrades: "" },
        { Subjects: "", tenthGrades: "" },
        { Subjects: "", tenthGrades: "" },
        { Subjects: "", tenthGrades: "" },
      ],
      GCEYear: "",
      GCERegistration: "",
      GCE: [
        { GCESubjects: "", GCEGrades: "" },
        { GCESubjects: "", GCEGrades: "" },
        { GCESubjects: "", GCEGrades: "" },
        { GCESubjects: "", GCEGrades: "" },
        { GCESubjects: "", GCEGrades: "" },
        { GCESubjects: "", GCEGrades: "" },
        { GCESubjects: "", GCEGrades: "" },
        { GCESubjects: "", GCEGrades: "" },
      ],
      tweleYear: "",
      tweleRegistration: "",
      twele: [
        { tSubjects: "", tGrades: "" },
        { tSubjects: "", tGrades: "" },
        { tSubjects: "", tGrades: "" },
        { tSubjects: "", tGrades: "" },
      ],
      subsidiary: [
        { tSubjects: "", tGrades: "" },
        { tSubjects: "", tGrades: "" },
        { tSubjects: "", tGrades: "" },
      ],
      GCEAYear: "",
      GCEARegistration: "",
      GCEA: [
        { ASubjects: "", AGrades: "" },
        { ASubjects: "", AGrades: "" },
        { ASubjects: "", AGrades: "" },
        { ASubjects: "", AGrades: "" },
      ],
      othSecYear: "",
      othRegistration: "",
      oth: [
        {
          Course: "",
          othAwards: "",
          othGradesAwd: "",
          othYear: "",
          othDur: "",
        },
        {
          Course: "",
          othAwards: "",
          othGradesAwd: "",
          othYear: "",
          othDur: "",
        },
        {
          Course: "",
          othAwards: "",
          othGradesAwd: "",
          othYear: "",
          othDur: "",
        },
      ],
      Post: [
        {
          DurF: "",
          DurT: "",
          PostUni: "",
          PostQua: "",
          PostMain: "",
          PostGradA: "",
        },
        {
          DurF: "",
          DurT: "",
          PostUni: "",
          PostQua: "",
          PostMain: "",
          PostGradA: "",
        },
        {
          DurF: "",
          DurT: "",
          PostUni: "",
          PostQua: "",
          PostMain: "",
          PostGradA: "",
        },
      ],
    },
    bankDraftNo: "",
    todaydate: "",
    agreementName: "",
    disability: "",
    profilePhoto: "",
    Signature: "",
    compSection: false,
    attachedCert: false,
    attachedEvi: false,
    appDeclaration: false,
    attachPaySlip: false,
    attachDraft: false,
    attachAttest: false,
  });
  // console.log("get", localStorage.getItem("eVBAB"))
  const classes = useStyles();
  let infoObj = {};

  useEffect(() => {
    console.log("userDetailsuuuu==",userDetails)
    if (userDetails.pstatus != 0 && userDetails.pstatus != 1) {
      navigate("/applyProgram");
    }
    application.agreementName = userDetails.name;
  });


  async function updateUserApplication(obj, action, pstatus, H, ind) {
    setSpin(true);
    let applicationid;
    if (userDetails.applicationid != undefined) {
      applicationid = userDetails.applicationid;
    } else {
      applicationid =
        userDetails.bpid + "-" + Math.round(new Date().getTime() / 1000);

      let sdata = { ...userDetails };
      sdata.applicationid = applicationid;
      dispatch(awsSignIn(sdata));
    }

    setIsLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        action: action,
        pid: userDetails.pid,
        bpid: userDetails.bpid,
        bname: userDetails.bname,
        pname: userDetails.pname,
        psname: userDetails.psname,
        appobj: obj,
        appid: applicationid,
        pstatus: pstatus,
        uname:
          application.firstName + application.maidenName + application.surName,
        evbab: userDetails.evbab,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        //Constants.GET_PROGRAM,
        Constants.UPDATE_USER_APPLICATION,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );

      console.log(bodyParam.body,"response " + JSON.stringify(response));
      H(true);
      setIsLoading(false);
      let sdata = { ...userDetails };
      sdata.pstatus = pstatus;
      dispatch(awsSignIn(sdata));
      navigate("/applyProgram");
      // if (action == 2 || pstatus == 2) {
      //   let sdata = { ...userDetails };
      //   sdata.pstatus = pstatus;
      //   dispatch(awsSignIn(sdata));
      //   navigate("/applyProgram");
      // } else {
      //   setActiveIndex(ind);
      // }
      setSpin(false);
    } catch (error) {
      console.log("getCategoryError", error);
    }
  }


  async function saveGeneralInfo() {
    let sdata = { ...userDetails };
    if (sdata.applicationid == undefined) {
      sdata.applicationid =
        sdata.bpid + "-" + Math.round(new Date().getTime() / 1000);
    }
    dispatch(awsSignIn(sdata));

    let action = 0;
    let pstatus = 2;
    let obj = application;
  console.log(sdata,"updateUserApplication",obj);
    updateUserApplication(obj, action, pstatus, H, 1);
  }

  async function uploadDocsToServer(file, fileUploadType) {
   
    setSpin(true);
    var val = Math.floor(1000 + Math.random() * 9000);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        type: "application",
        filetype: file.type,
        filename: val + "-" + file.name,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
   
   
    try {
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );

      console.log("responsepRESIGNED_URL" + JSON.stringify(response));
      fileUpload(file, response, val, fileUploadType);
      
    } catch (error) {
      setSpin(false);
      console.log("getCategoryError", error);
    }
  }

  async function fileUpload(file, url, val, fileUploadType) {
    setSpin(true);
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then((res) => {
    
       console.log("fileUploadType",fileUploadType)
        if(fileUploadType == 'profile'){
          //application.profilePhoto = val + "-" + file.name;
          setApplication({...application,profilePhoto: (val + "-" + file.name)});
        } else if(fileUploadType == 'sign'){
          setApplication({...application,Signature: (val + "-" + file.name)});
        } else if(fileUploadType == '10th'){
          //application.tenthdoc = val + "-" + file.name;
          setApplication({...application,tenthdoc: (val + "-" + file.name)});
        } else if(fileUploadType == '12th'){
          //application.tweledoc = val + "-" + file.name;
          setApplication({...application,tweledoc: (val + "-" + file.name)});
        } else if(fileUploadType == 'Degree-Marks-card'){
          //application.degreemarksdoc = val + "-" + file.name;
          setApplication({...application,degreemarksdoc: (val + "-" + file.name)});
        } else if(fileUploadType == 'Masters-Marks-card'){
          //application.mastersdoc = val + "-" + file.name;
          setApplication({...application,mastersdoc: (val + "-" + file.name)});
        } else if(fileUploadType == 'idproof'){
          //application.idproofdoc = val + "-" + file.name;
          setApplication({...application,idproofdoc: (val + "-" + file.name)});
        }
       
        console.log(application,"fileUpload==",res);
        setSpin(false);
      })
      .catch((err) => {
        console.error(err);
        setSpin(false);
      });
  }


  async function handleProfilePhoto(e) {
    setPIssueTextUpload('');
    if (e.target.files != undefined) {
      console.log("inside");
      let selectedFile = e.target.files[0];
      let extend =
        selectedFile.name.substring(
          selectedFile.name.lastIndexOf(".") + 1,
          selectedFile.name.length
        ) || selectedFile.name;
      console.log(e.target.files[0].size,"inside123", extend);
      let fileUploadType = "profile";
      if (e.target.files[0].size < 50 * 1024) {
        setPIssueTextUpload("file size is less than 50kb");
        return;
      } else if (e.target.files[0].size > 250 * 1024) {
        setPIssueTextUpload("file size is greater than 250kb");
        return;
      } else if (
        extend == "JPG" ||
        extend == "PNG" ||
        extend == "JPEG" ||
        extend == "jpg" ||
        extend == "png" ||
        extend == "jpeg"
      ) {
        console.log("extendmatch==",e.target.files);
        console.log("h3", e.target.files[0]);
        let file = e.target.files[0];
        refselectedCourseFile.current = file;
        uploadDocsToServer(file, fileUploadType);
      } else {
        setPIssueTextUpload(
          "Profile photo Only images with .jpg, .jpeg, .png extension are allowed."
        );
        return;
      }
    }
  }

  async function handleSignature(e,app) {
    setSIssueTextUpload('');
    if (e.target.files != undefined) {
      let selectedFile = e.target.files[0];
      let extend =
        selectedFile.name.substring(
          selectedFile.name.lastIndexOf(".") + 1,
          selectedFile.name.length
        ) || selectedFile.name;
      let fileUploadType = "sign";

      if (e.target.files[0].size < 50 * 1024) {
        setSIssueTextUpload("file size is less than 50kb");
        return;
      } else if (e.target.files[0].size > 250 * 1024) {
        setSIssueTextUpload("file size is greater than 250kb");
        return;
      } else if (
        extend == "JPG" ||
        extend == "PNG" ||
        extend == "JPEG" ||
        extend == "jpg" ||
        extend == "png" ||
        extend == "jpeg"
      ) {
        console.log(e.target.files);
        console.log("-h", e.target.files[0]);
        let file = e.target.files[0];
        //setTranscripts(file);

        uploadDocsToServer(file, fileUploadType,app);
      } else {
        setSIssueTextUpload(
          "Transcripts Only images with .jpg, .jpeg, .png extension are allowed."
        );
        return;
      }
    }
  }
  async function handledocument(e,fileUpType) {
    setSIssueTextUpload('');
    if (e.target.files != undefined) { 
      let extend = e.target.files[0].name.substring(
        e.target.files[0].name.lastIndexOf(".") + 1,
        e.target.files[0].name.length
        ) || e.target.files[0].name;
    
      if (e.target.files[0].size < 50 * 1024) {
        console.log("fileeesize1==",e.target.files[0].size)
        setSIssueTextUpload("file size is less than 50kb");
        return;
      } else if (e.target.files[0].size > 250 * 1024) {
        setSIssueTextUpload("file size is greater than 250kb");
        console.log("fileeesize2==",e.target.files[0].size)
        return;
      } else if (
        extend == "JPG" ||
        extend == "PNG" ||
        extend == "JPEG" ||
        extend == "jpg" ||
        extend == "png" ||
        extend == "jpeg"
      ) {
        console.log("fileeesize3==",e.target.files[0].size)
        let file = e.target.files[0];
        
        uploadDocsToServer(file, fileUpType);
      } else {
        console.log("fileeesize=4=",e.target.files[0].size)
        setSIssueTextUpload( fileUpType+ " Only images with .jpg, .jpeg, .png extension are allowed." );
        return;
      }
    }
  }
  function isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}
  async function handleSubmit() {
   
    setNameIssueText("");
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if(application.profilePhoto === undefined || application.profilePhoto === ''){
      setNameIssueText("Please select Profile photo");
      return;
    } else if (
      application.firstName === "" ||
      application.firstName.replace(/\s/g, "").length === 0
    ) {
      console.log("c9");
      setNameIssueText("Name cannot be empty");
      return;
    } else if (application.email !== "" && !pattern.test(application.email)) {
      setNameIssueText("*Please enter valid email-ID.");
        return;
    } else if (
      application.appTitle === "" ||
      application.appTitle.replace(/\s/g, "").length === 0
    ) {
      console.log("c7");
      setNameIssueText("Please choose a title");
      return;
    } else if (
      application.phoneNo === "" ||
      application.phoneNo.replace(/\s/g, "").length === 0
    ) {
      console.log("c6");
      setNameIssueText("Phone number cannot be empty");
      return;
    } else if (
      application.nic === "" ||
      application.nic.replace(/\s/g, "").length === 0
    ) {
      console.log("c5");
      setNameIssueText("NIC number cannot be empty");
      return;
    } else if (
      application.nationality === "" ||
      application.nationality.replace(/\s/g, "").length === 0
    ) {
      console.log("c4");
      setNameIssueText("Please enter nationality");
      return;
    } else if (
      application.address === "" ||
      application.address.replace(/\s/g, "").length === 0
    ) {
      console.log("c3");
      setNameIssueText("Please enter address");
      return;
    } else if (
      application.todaydate === "" ||
      application.todaydate.replace(/\s/g, "").length === 0
    ) {
      console.log("c2");
      setNameIssueText("Please select date");
      return;
    } else if (
      application.agreementName === "" ||
      application.agreementName.replace(/\s/g, "").length === 0
    ) {
      console.log("c1");
      setNameIssueText("Please enter declaration applicant fields");
      return;
      
    } else if(application.certificate.tenthYear === undefined || application.certificate.tenthYear === ''){
      setNameIssueText("Please enter 10th Standard year");
      return;
    } else if(application.certificate.tenthRegistration === undefined || application.certificate.tenthRegistration === ''){
      setNameIssueText("Please enter 10th Standard registration No");
      return;
    } else if(application.certificate?.tenth[0].Subjects === undefined || application.certificate?.tenth[0].Subjects === ''){
      setNameIssueText("Please enter 10th Standard subjects");
      return;
    } else if(application.certificate?.tenth[0].tenthGrades === undefined || application.certificate?.tenth[0].tenthGrades === ''){
      setNameIssueText("Please enter 10th Standard grades");
      return;
    } else if(application.certificate.tweleYear === undefined || application.certificate.tweleYear === ''){
      setNameIssueText("Please enter 12th Standard year");
      return;
    } else if(application.certificate.tweleRegistration === undefined || application.certificate.tweleRegistration === ''){
      setNameIssueText("Please enter 12th Standard registration No");
      return;
    } else if(application.certificate?.twele[0].tSubjects === undefined 
      || application.certificate?.twele[0].tSubjects === '' || application.certificate?.twele[0].tSubjects?.replace(/\s/g, "").length === 0){
      setNameIssueText("Please enter 12th Standard subjects");
      return;
    } else if(application.certificate?.twele[0].tGrades === undefined 
      || application.certificate?.twele[0].tGrades === '' || application.certificate?.twele[0].tGrades?.replace(/\s/g, "").length === 0){
      setNameIssueText("Please enter 12th Standard grades");
      return;
    } else if(application.Signature === undefined 
      || application.Signature === '' || application.Signature?.replace(/\s/g, "").length === 0){
      setNameIssueText("Please select Signature file");
      return;
    } else if(application.tenthdoc === undefined 
      || application.tenthdoc === '' || application.tenthdoc?.replace(/\s/g, "").length === 0){
      setNameIssueText("Please select 10th certificate");
      return;
    } else if(application.tweledoc === undefined 
      || application.tweledoc === '' || application.tweledoc?.replace(/\s/g, "").length === 0){
      setNameIssueText("Please select 12th certificate");
      return;
    }
    //  else if(application.degreemarksdoc === undefined 
    //   || application.degreemarksdoc === '' || application.degreemarksdoc?.replace(/\s/g, "").length === 0){
    //   setNameIssueText("Please select degree marks card file" );
    //   return;
    // } else if(application.mastersdoc === undefined  
    //   || application.mastersdoc === '' || application.mastersdoc?.replace(/\s/g, "").length === 0){
    //   setNameIssueText("Please select masters file");
    //   return;
    // } 
    else if(application.idproofdoc === undefined 
      || application.idproofdoc === '' || application.idproofdoc?.replace(/\s/g, "").length === 0){
      setNameIssueText("Please select ID proof file");
      return;
    }  else {
      console.log("saveGeneralInfo", application);
      //saveGeneralInfo();
    } 
    console.log("outsaveGeneralInfo", application);
    //saveGeneralInfo();
  }
  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
  function ApplicationFormW() {
    return (
      <Container text style={{ marginTop: "7em" }}>
        <div style={{ paddingBottom: "2rem", borderBottom: "2px solid",textAlign:'center' }}>
        <img src={`https://${
                  Constants.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/jssaher-cert.jpg`}
                alt=""  style={{width:'50%'}}/>
        </div>
        <div class="headerContainer">
          <div>
            <Header as="h1" style={{ paddingTop: "2rem", marginBottom: 0 }}>
              APPLICATION FOR ADMISSION
            </Header>
            <h3 style={{ marginTop: 0 }}>ACADEMIC YEAR 2024 - 2025</h3>
          </div>
          <div class="profile">
            {/* <label for="img">PHOTO</label> */}
            <form style={{position:"relative"}}>
            <input
              type="file"
              id="pfiles"
              name="file"
              accept="image/*"
              required
              style={{ width: "15rem", height: "4rem", visibility: "hidden"}}
              onChange={(e) => {
                handleProfilePhoto(e);
              }}
            />
             <label
              for="pfiles"
              style={{ position: "absolute", right: "10px", bottom: "6px", right: "2px", bottom: "-48px",zIndex:"10" }}
            >
              <AddAPhotoIcon style={{ color: "#0f80a4", cursor: "pointer" }} />
            </label>
            {refselectedCourseFile.current === undefined && (
              <PersonIcon style={{ color: "#0f80a4", cursor: "pointer",position: "absolute",
              right: "58px", bottom: "-14px" }}/>
            )}
            {refselectedCourseFile.current !== undefined && (
              <img style={{ width: '100%', objectFit: 'cover',position: "absolute", top:"0px",height: '9rem' }}
                src={URL.createObjectURL(refselectedCourseFile.current)}
                alt="courseimage"
              />
            )}
             </form>
             <h5 style={{ color: "red" }}>{pissueTextUpload}</h5>
          </div>
        </div>

        <form style={{ textAlign: "left" }} className='mainform'>
          <h3 class="subHeading">A. DETAILS OF APPLICANT</h3>
          <ol>
            <li>
             
                <div class="checkboxContent">
                  Title:
                  <input
                    type="radio"
                    name="title"
                    value="Mr"
                    defaultChecked={application.appTitle === "Mr"}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      console.log("ffs", e.target.value);
                      application.appTitle = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                  <label>Mr</label>
                  <input
                    type="radio"
                    value="Mrs"
                    name="title"
                    defaultChecked={application.appTitle === "Mrs"}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.appTitle = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                  <label>Mrs</label>
                  <input
                    type="radio"
                    value="Miss"
                    name="title"
                    defaultChecked={application.appTitle === "Miss"}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.appTitle = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                  <label>Miss</label>
                </div>
            
            </li>
            <li>
             <div>
             Surname:
                <div class="ui input inputContainer">
                  <input
                    type="text"
                    class="inputField"
                    defaultValue={application.surName}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.surName = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>
             </div>
               
             
            </li>
            <li>
             <div>
             First Name:
                <div class="ui input inputContainer">
                  <input
                    type="text"
                    class="inputField"
                    defaultValue={application.firstName}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.firstName = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>
             </div>
            </li>
            <li>
             
                Maiden Name (If applicable):
                <div class="ui input inputContainer4">
                  <input
                    type="text"
                    class="inputField"
                    defaultValue={application.maidenName}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.maidenName = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>
            
            </li>
            <li>
            
                Date of Birth:
                <div class="ui input inputContainer2">
                  <input
                    type="date"
                    id="birthday"
                    name="birthday"
                    class="inputField"
                    max={moment().subtract(10, 'years').format("YYYY-MM-DD")}
                    defaultValue={application.dob}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      console.log("agge",e.target.value.toString()) ;
                      application.dob = e.target.value; 
                      setApplication(application);
                      setTimeout(setApplication({...application,age:getAge(e.target.value.toString())}), 20000);
                    }}
                  />
                </div>
                Age:
                <div class="ui input inputContainer2">
                <p  className="emailp">{application.age} </p>
                  {/* <input
                   type="number"
                    id="age"
                    name="age"
                    class="inputField"
                    defaultValue={application.age}
                    onChange={(e) => {
                        application.age = e.target.value.replace(/\D/g, '');
                        
                      //setApplication(Pobject)
                    }}
                  /> */}
                </div>
             
            </li>
            <li>
             <div>
             Nationality:
                <div class="ui input inputContainer">
                  <input
                    type="text"
                    class="inputField"
                    required
                    defaultValue={application.nationality}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.nationality = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>
             </div>
            </li>
            <li>
              Identification:
             
                NIC No.
                <div class="ui input inputContainer2">
                  <input
                    type="text"
                    class="inputField"
                    required
                    defaultValue={application.nic}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.nic = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>
                Passport No.
                <div class="ui input inputContainer2">
                  <input
                    type="text"
                    class="inputField"
                    required
                    defaultValue={application.passport}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.passport = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>
             
            </li>
            <li>
              Residential Address:
                <div class="ui inputContainer3">
                  <TextArea
                    required
                    defaultValue={application.address}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.address = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>
              
            </li>
            <li> 
                Telephone (M):
                <div class="ui input inputContainer2">
                  <input
                     type="number"
                    class="inputField"
                    pattern="[0-9]"
                    required
                    defaultValue={application.phoneNo}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.phoneNo = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>
                Telephone (H):
                <div class="ui input inputContainer2">
                  <input
                    type="number"
                    class="inputField"
                    pattern="[0-9]"
                    defaultValue={application.phoneNo}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.phoneNo = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>
            </li>
            <li> 
                Email Address:
                <div class="ui input inputContainer">
                  <p  className="emailp">{application.email} </p>
                </div>
              
            </li>
          </ol> 
          <h3 class="subHeading">
            B. PROGRAMME OF STUDY APPLIED FOR (In order of priority)
          </h3>
          <table class="ui celled table">
            <thead>
              <tr>
                <th>SN</th>
                <th>Programme Titles</th>
                <th>Full-Time</th>
                <th>Part-Time</th>
              </tr>
            </thead>
            <tbody>
              {application.programmeTable.map((item, i) => {
                return (
                  <tr key={i}>
                    <td data-label="sn">
                      <p class="tablepField" 
                      >{i + 1}</p>
                    </td>
                    <td data-label="title" data-tip={i === 0 ? userDetails.pname : item.title}>
                    <TextArea
                        type="text"
                        class="tableInputField"
                        defaultValue={i === 0 ? userDetails.pname :item.title }
                        title={i === 0 ? userDetails.pname : item.title}
                        style={{pointerEvents: i === 0 && 'none'}}
                        onChange={(e) => {
                          console.log("hi");
                          //let Pobject = {...application};
                          item.title = e.target.value;

                          //setApplication(Pobject)
                        }}
                      />
                    </td>
                    <td data-label="ftime">
                    <TextArea
                        type="text"
                        class="tableInputField"
                        defaultValue={item.ftime}
                        onChange={(e) => {
                          //let Pobject = {...application};
                          item.ftime = e.target.value;

                          //setApplication(Pobject)
                        }}
                      />
                    </td>
                    <td data-label="ptime">
                    <TextArea
                        type="text"
                        class="tableInputField"
                        defaultValue={item.ptime}
                        onChange={(e) => {
                          //let Pobject = {...application};
                          item.ptime = e.target.value;

                          //setApplication(Pobject)
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table> 
          <h3 class="subHeading">C. ACADEMIC RECORDS</h3>
          <p>
            Please attach certified copies of all your qualifications and
            academic transcripts.
          </p>
          <ol>
            <li>
              Cambridge School Certificate / 10th Standard
             
                Year:
                <div class="ui input inputContainer2">
                  <input
                    type="number"
                    class="inputField"
                    defaultValue={application.certificate.tenthYear}
                    onChange={(e) => {
                      //let Pobject = { ...application };
                      application.certificate.tenthYear = e.target.value;

                      //setApplication(Pobject);
                    }}
                  />
                </div>
                Index/Registration No:
                <div class="ui input inputContainer2">
                  <input
                    type="text"
                    class="inputField"
                    defaultValue={application.certificate.tenthRegistration}
                    onChange={(e) => {
                      //let Pobject = { ...application };
                      application.certificate.tenthRegistration =
                        e.target.value;

                      //setApplication(Pobject);
                    }}
                  />
                </div>
             
            
                <table class="ui celled table">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Subjects</th>
                      <th>Grades</th>
                    </tr>
                  </thead>
                  <tbody>
                    {application.certificate.tenth.map((item, i) => {
                      return (
                        <tr key={'cert'+i}>
                          <td data-label="sn1">
                          <p class="tablepField" 
                      >{i + 1}</p>
                          </td>
                          <td data-label="subject1">
                            <input
                              type="text"
                              class="tableInputField"
                              defaultvalue={item.Subjects}
                              onChange={(e) => {
                                //let Pobject = {...application};
                                item.Subjects = e.target.value;

                                //setApplication(Pobject)
                              }}
                            />
                          </td>
                          <td data-label="grade1">
                            <input
                              type="text"
                              class="tableInputField"
                              defaultvalue={item.tenthGrades}
                              onChange={(e) => {
                                //let Pobject = {...application};
                                item.tenthGrades = e.target.value;

                                //setApplication(Pobject)
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              
            </li>
            <li>
              GCE ‘O’ Level (if applicable)
            
                Year:
                <div class="ui input inputContainer2">
                  <input
                    type="number"
                    class="inputField"
                    defaultValue={application.certificate.GCEYear}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.certificate.tenthGrades = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>
                Index/Registration No:
                <div class="ui input inputContainer2">
                  <input
                    type="text"
                    class="inputField"
                    defaultValue={application.certificate.GCERegistration}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.certificate.GCERegistration = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>
             
            
                <table class="ui celled table">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Main Subjects </th>
                      <th>Grades</th>
                    </tr>
                  </thead>
                  <tbody>
                    {application.certificate.GCE.map((item, i) => {
                      return (
                        <tr key={'gce'+i}>
                          <td data-label="sn1">
                          <p class="tablepField" 
                      >{i + 1}</p>
                          </td>
                          <td data-label="subject1">
                            <input
                              type="text"
                              class="tableInputField"
                              defaultValue={item.GCESubjects}
                              onChange={(e) => {
                                //let Pobject = {...application};
                                item.GCESubjects = e.target.value;

                                //setApplication(Pobject)
                              }}
                            />
                          </td>
                          <td data-label="grade1">
                            <input
                              type="text"
                              class="tableInputField"
                              defaultValue={item.GCEGrades}
                              onChange={(e) => {
                                //let Pobject = {...application};
                                item.GCEGrades = e.target.value;

                                //setApplication(Pobject)
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
             
            </li>
            <li>
              Cambridge Higher School Certificate / 12th Standard
            
                Year:
                <div class="ui input inputContainer2">
                  <input
                    type="number"
                    class="inputField"
                    defaultValue={application.certificate.tweleYear}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.certificate.tweleYear = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>
                Index/Registration No:
                <div class="ui input inputContainer2">
                  <input
                    type="text"
                    class="inputField"
                    defaultValue={application.certificate.tweleRegistration}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.certificate.tweleRegistration =
                        e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>
             
            
                <table class="ui celled table">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Main Subjects </th>
                      <th>Grades</th>
                    </tr>
                  </thead>
                  <tbody>
                    {application.certificate.twele.map((item, i) => {
                      return (
                        <tr key={'tw'+i}>
                          <td data-label="sn1">
                          <p class="tablepField" 
                      >{i + 1}</p>
                          </td>
                          <td data-label="subject1">
                            <input
                              type="text"
                              class="tableInputField"
                              defaultValue={item.tSubjects}
                              onChange={(e) => {
                                //let Pobject = {...application};
                                item.tSubjects = e.target.value;

                                //setApplication(Pobject)
                              }}
                            />
                          </td>
                          <td data-label="grade1">
                            <input
                              type="text"
                              class="tableInputField"
                              defaultValue={item.tGrades}
                              onChange={(e) => {
                                //let Pobject = {...application};
                                item.tGrades = e.target.value;

                                //setApplication(Pobject)
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })} 
                  </tbody>
                </table>
                <table class="ui celled table">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Subsidiary Subjects (if applicable) </th>
                      <th>Grades</th>
                    </tr>
                  </thead>
                  <tbody>
                  {application.certificate.subsidiary.map((item, i) => {
                      return (
                        <tr key={'tw'+i}>
                          <td data-label="sn1">
                          <p class="tablepField" 
                      >{i + 1}</p>
                          </td>
                          <td data-label="subject1">
                            <input
                              type="text"
                              class="tableInputField"
                              defaultValue={item.tSubjects}
                              onChange={(e) => {
                                item.tSubjects = e.target.value;
                              }}
                            />
                          </td>
                          <td data-label="grade1">
                            <input
                              type="text"
                              class="tableInputField"
                              defaultValue={item.tGrades}
                              onChange={(e) => {
                                item.tGrades = e.target.value;
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  </table>
            
            </li>

            <li>
              GCE ‘A’ Level (if applicable)
              <table class="ui celled table">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Main Subjects</th>
                    <th>Grades</th>
                  </tr>
                </thead>
                <tbody>
                  {application.certificate.GCEA.map((item, i) => {
                    return (
                      <tr key={'gcea'+i}>
                        <td data-label="sn1">
                        <p class="tablepField" 
                      >{i + 1}</p>
                        </td>
                        <td data-label="subject1">
                          <input
                            type="text"
                            class="tableInputField"
                            defaultValue={item.ASubjects}
                            onChange={(e) => {
                              //let Pobject = {...application};
                              item.ASubjects = e.target.value;

                              //setApplication(Pobject)
                            }}
                          />
                        </td>
                        <td data-label="grade1">
                          <input
                            type="text"
                            class="tableInputField"
                            defaultValue={item.AGrades}
                            onChange={(e) => {
                              //let Pobject = {...application};
                              item.AGrades = e.target.value;

                              //setApplication(Pobject)
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </li>
            <li>
              Other Secondary / High School Qualifications (if applicable)
             
                Year:
                <div class="ui input inputContainer2">
                  <input
                    type="number"
                    class="inputField"
                    defaultValue={application.certificate.othSecYear}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.certificate.othSecYear = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>
                Index/Registration No:
                <div class="ui input inputContainer2">
                  <input
                    type="text"
                    class="inputField"
                    defaultValue={application.certificate.othRegistration}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.certificate.othRegistration = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>
            
             
                <table class="ui celled table">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Course / Programme</th>
                      <th>Awarding Body</th>
                      <th>Grades Awarded</th>
                      <th>Year</th>
                      <th>Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {application.certificate.oth.map((item, i) => {
                      return (
                        <tr key={'oth'+i}>
                          <td data-label="sn1">
                          <p class="tablepField" 
                      >{i + 1}</p>
                          </td>
                          <td data-label="subject1">
                            <input
                              type="text"
                              class="tableInputField"
                              defaultValue={item.Course}
                              onChange={(e) => {
                                //let Pobject = {...application};
                                item.Course = e.target.value;

                                //setApplication(Pobject)
                              }}
                            />
                          </td>
                          <td data-label="grade1">
                            <input
                              type="text"
                              class="tableInputField"
                              defaultValue={item.othAwards}
                              onChange={(e) => {
                                //let Pobject = {...application};
                                item.othAwards = e.target.value;

                                //setApplication(Pobject)
                              }}
                            />
                          </td>
                          <td data-label="subject1">
                            <input
                              type="text"
                              class="tableInputField"
                              defaultValue={item.othGradesAwd}
                              onChange={(e) => {
                                //let Pobject = {...application};
                                item.othGradesAwd = e.target.value;

                                //setApplication(Pobject)
                              }}
                            />
                          </td>
                          <td data-label="grade1">
                            <input
                              type="text"
                              class="tableInputField"
                              defaultValue={item.othYear}
                              onChange={(e) => {
                                //let Pobject = {...application};
                                item.othYear = e.target.value;

                                //setApplication(Pobject)
                              }}
                            />
                          </td>
                          <td data-label="grade1">
                            <input
                              type="number"
                              class="tableInputField"
                              defaultValue={item.othDur}
                              onChange={(e) => {
                                //let Pobject = {...application};
                                item.othDur = e.target.value;

                                //setApplication(Pobject)
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
            
            </li>
            <li>
              Post-Secondary Education / Graduate Degree
              <table class="ui celled table">
                <thead>
                  <tr>
                    <th>
                      Duration <br />
                      From / To
                    </th>
                    <th>Post-Secondary Institution / University</th>
                    <th>Qualifications Obtained</th>
                    <th>Main Subject Area</th>
                    <th>Grades Awarded (Specify Class / Division / Honours)</th>
                  </tr>
                </thead>
                <tbody>
                  {application.certificate.Post.map((item, i) => {
                    return (
                      <tr key={'post'+i}>
                        <td data-label="sn1">
                          From
                          <input
                            type="date"
                            id="today"
                            name="today"
                            class="inputField"
                            defaultValue={item.DurF}
                            onChange={(e) => {
                              //let Pobject = {...application};
                              item.DurF = e.target.value;

                              //setApplication(Pobject)
                            }}
                          />
                          To
                          <input
                            type="date"
                            id="today"
                            name="today"
                            class="inputField"
                            defaultValue={item.DurT}
                            onChange={(e) => {
                              //let Pobject = {...application};
                              item.DurT = e.target.value;

                              //setApplication(Pobject)
                            }}
                          />
                        </td>
                        <td data-label="subject1">
                          <TextArea
                            type="text"
                            class="tableInputField"
                            defaultValue={item.PostUni}
                            onChange={(e) => {
                              //let Pobject = {...application};
                              item.PostUni = e.target.value;

                              //setApplication(Pobject)
                            }}
                          />
                        </td>
                        <td data-label="grade1">
                          <TextArea
                            type="text"
                            class="tableInputField"
                            defaultValue={item.PostQua}
                            onChange={(e) => {
                              //let Pobject = {...application};
                              item.PostQua = e.target.value;

                              //setApplication(Pobject)
                            }}
                          />
                        </td>
                        <td data-label="subject1">
                          <TextArea
                            type="text"
                            class="tableInputField"
                            defaultValue={item.PostMain}
                            onChange={(e) => {
                              //let Pobject = {...application};
                              item.PostMain = e.target.value;

                              //setApplication(Pobject)
                            }}
                          />
                        </td>
                        <td data-label="grade1">
                          <TextArea
                            type="text"
                            class="tableInputField"
                            defaultValue={item.PostGradA}
                            onChange={(e) => {
                              //let Pobject = {...application};
                              item.PostGradA = e.target.value;

                              //setApplication(Pobject)
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <p>
                <b>NOTE: </b>Applicants whose studies were not in English medium
                should submit an attestation of proficiency in English which is
                internationally recognised, either an IELTS or a TOEFL score or
                similar.
              </p>
              {/* <div class="ui checkbox"> */}
              <input
                type="checkbox"
                name="check"
                //value='check'
                //checked={value === 'check'}
                onClick={(e) => {
                  application.attachAttest = e.target.checked;
                }}
              />
              <label>
                Attached attestation of IELTS or TOEFL test or similar
              </label>
              {/* </div> */}
            </li>
          </ol>

          <h3 class="subHeading">
            D. PAYMENT OF APPLICATION FEE – FOR MAURITIAN NATIONALS ONLY
          </h3>
          <ol type="A">
            <li>
              For online application
              <br />
              {/* <div class="ui checkbox"> */}
              <input
                type="checkbox"
                name="check"
                //value='check'
                //checked={value === 'check'}
                onClick={(e) => {
                  application.attachPaySlip = e.target.checked;
                }}
              />
              <label>Attached proof of payment (transfer slip from bank)</label>
              {/* </div> */}
              <p>
                <b>NOTE: </b>&nbsp;Bank details of JSS Academy of Higher
                Education and Research (Mauritius) <br />
                <div class="spaceBetween">
                  <label>Name of Bank: &nbsp; Bank of Baroda</label>
                  <label>Branch: &nbsp; Head Office, Port Louis</label>
                </div>
                <div class="spaceBetween">
                  <label>Account No.:&nbsp;90310200004706</label>
                  <label>Swift Code:&nbsp; BARBMUMU</label>
                </div>
                <p>IBAN: &nbsp;MU33BARB02310002000047060 00MUR</p>
              </p>
            </li>
            <li>
              For direct application, submit bank draft together with the
              application form to JSS Academy of Higher Education and Research
              (Mauritius), Avenue Droopnath Ramphul, Bonne Terre, Vacoas.
              {/*  <div class="ui checkbox"> */}
              <input
                type="checkbox"
                name="check"
                //value='check'
                //checked={value === 'check'}
                onClick={(e) => {
                  application.attachDraft = e.target.checked;
                }}
              />
              <label>
                Attached Bank Draft No.
                <div class="ui input inputContainer2">
                  <input
                    type="text"
                    class="inputField"
                    defaultValue={application.bankDraftNo}
                    onChange={(e) => {
                      //let Pobject = {...application};
                      application.bankDraftNo = e.target.value;

                      //setApplication(Pobject)
                    }}
                  />
                </div>{" "}
                for MUR 1,000 / USD 30 in favour of JSS Academy of Higher
                Education and Research (Mauritius)
              </label>
              {/*  </div> */}
            </li>
          </ol>

          <h3 class="subHeading">E. DECLARATION OF APPLICANT</h3>
          <p>Please read and sign the following applicant certification. </p>
          <p>This application is not valid unless signed and dated.</p>
          <ol>
            <li>
              I,
              <div class="ui input inputContainer2">
                <input
                  type="text"
                  class="inputField"
                  style={{pointerEvents:'none'}}
                  defaultValue={userDetails.name} 
                />
              </div>{" "}
              solemnly declare that if admitted to JSS Academy of Higher
              Education and Research (Mauritius), I will
              <ol type="a">
                <li>
                  Diligently follow the Programme of Study for which I am
                  enrolled until its termination;
                </li>
                <li>
                  Inform the CEO, in writing and without delay, if I withdraw
                  from the Programme;
                </li>
                <li>
                  Conform to all the rules and regulations of JSS Academy of
                  Higher Education and Research (Mauritius);
                </li>
                <li>
                  Pay in advance all fees and dues required until the completion
                  of my studies;
                </li>
                <li>
                  Incur the cost of recovering any additional outstanding
                  balance due to JSS Academy of Higher Education and Research
                  (Mauritius);
                </li>
                <li>
                  Inform the Institution if I am suffering from any illness or
                  incapacity.
                </li>
              </ol>
            </li>
            <li>
              I acknowledge that JSS Academy of Higher Education and Research
              (Mauritius) reserves the right to seek information from any
              relevant bodies as to the standing of my claimed qualifications
              and experience and to reserve any decision regarding the admission
              made on the basis of incorrect or incomplete information.
            </li>
            <li>
              I do not have any physical disability / I have the following
              disabilities
              <div class="ui input inputContainer2">
                <input
                  type="text"
                  class="inputField"
                  defaultValue={application.disability}
                  onChange={(e) => {
                    //let Pobject = {...application};
                    application.disability = e.target.value;

                    //setApplication(Pobject)
                  }}
                />
              </div>
            </li>
            <li>
              I acknowledge that the submission of incorrect or incomplete
              information may result in the withdrawal of any offer and / or
              cancellation of enrolment at any stage.
            </li>
            <li>
              I acknowledge that it is my responsibility to provide all relevant
              and required documentary evidence of my qualifications and
              experiences.
            </li>
            <li>
              I declare that the above information provided is true and correct.
            </li>
          </ol>
          <h3 class="subHeading">F. DOCUMENTS </h3>
          <ol style={{listStyleType:'none'}}>
            <li>
              <div>
                1.SC Marks Card/10th Marks card
                <form method="post" enctype="multipart/form-data">
              <input type="file"   name="10th"  id='10th' accept="image/png, image/jpeg"
                required className="fileinput"
                onChange={(e) => {
                  handledocument(e,'10th')}}
              />
              <label for="10th" className="filelabel">Choose file</label>
             {application.tenthdoc && <label>{application.tenthdoc}</label>}
              </form>
              </div>
            </li>
            <li>
              <div>
                2.HSC Marks card/12th Marks card
                <form method="post" enctype="multipart/form-data">
              <input type="file"   name="12th" id="12th"  accept="image/png, image/jpeg"
                required className="fileinput"
                onChange={(e) => {
                  handledocument(e,'12th')}}
              />
              <label for="12th" className="filelabel"> Choose file </label>
              {application.tweledoc && <label>{application.tweledoc}</label>}
              </form>
              </div>
            </li>
            <li>
              <div>
                3.Degree Marks card and certificate
                <form method="post" enctype="multipart/form-data">
              <input type="file"   name="Degree-Marks-card" id="Degree-Marks-card" accept="image/png, image/jpeg"
                required className="fileinput"
                onChange={(e) => {
                  handledocument(e,'Degree-Marks-card')}}
              />
              <label for="Degree-Marks-card" className="filelabel"> Choose file </label>
              {application.degreemarksdoc && <label>{application.degreemarksdoc}</label>}
              </form>
              </div>
            </li>
            <li>
              <div>
                4.Masters Marks card and certificate
                <form method="post" enctype="multipart/form-data">
              <input type="file"   name="Masters-Marks-card" id="Masters-Marks-card" accept="image/png, image/jpeg"
                required className="fileinput"
                onChange={(e) => {
                  handledocument(e,'Masters-Marks-card')}}
              />
              <label for="Masters-Marks-card" className="filelabel"> Choose file </label>
              {application.mastersdoc && <label>{application.mastersdoc}</label>}
              </form>
              </div>
            </li>
            <li>
              <div>
                5.ID Proof, Address Proof and DOB Proof
                <form method="post" enctype="multipart/form-data">
              <input type="file"   name="idproof" id="idproof"  accept="image/png, image/jpeg"
                required className="fileinput"
                onChange={(e) => {
                  handledocument(e,'idproof')}}
              />
              <label for="idproof" className="filelabel">Choose file </label>
              {application.idproofdoc && <label>{application.idproofdoc}</label>}
              </form>
              </div>
            </li>
            </ol>
          <h3 class="subHeading">G. APPLICATION CHECKLIST</h3>
          <ol>
            <li>
              {/* <div class="ui checkbox"> */}
              <input
                type="checkbox"
                label="Completed all relevant sections of this form"
                name="check"
                defaultChecked={application.compSection}
                //value={true}
                //checked={value === 'check'}
                onClick={(e) => {
                  application.compSection = e.target.checked;
                }}
              />
              <label>Completed all relevant sections of this form</label>
              {/*  </div> */}
            </li>
            <li>
              {/* <div class="ui checkbox"> */}
              <input type="checkbox"
                label="Attached evidence of my name change (if applicable)"
                name="check"
                //value='check'
                defaultChecked={application.attachedEvi}
                onClick={(e) => {
                  console.log(e.target.checked)
                  application.attachedEvi = e.target.checked;
                }} />
              <label>Attached evidence of my name change (if applicable)</label>
              {/*  </div> */}
            </li>
            <li>
              {/* <div class="ui checkbox"> */}
              <input
                type="checkbox"
                label="Attached certified copies of my qualifications"
                name="check"
                //value='check'
                defaultChecked={application.attachedCert}
                //onChange={(e, data) => setValue(data.value)}
                onClick={(e) => {
                  application.attachedCert = e.target.checked;
                }}
              />
              <label>Attached certified copies of my qualifications</label>
              {/*  </div> */}
            </li>
            <li>
              {/*  <div class="ui checkbox"> */}
              <input
                type="checkbox"
                label="Signed and dated the applicant declaration Section E above"
                name="check"
                //value='check'
                defaultChecked={application.appDeclaration}
                onClick={(e) => {
                  application.appDeclaration = e.target.checked;
                }}
              />
              <label>
                Signed and dated the applicant declaration Section E above
              </label>
              {/* </div> */}
            </li>
          </ol>
         
            Date:
            <div class="ui input inputContainer2">
              <input
                type="date"
                id="birthday"
                name="birthday"
                class="inputField"
                min={moment().format("YYYY-MM-DD")}
                required
                defaultValue={application.todaydate}
                onChange={(e) => {
                  //let Pobject = {...application};
                  application.todaydate = e.target.value;

                  //setApplication(Pobject)
                }}
              />
            </div>
           
            <div class="ui input inputContainer2">
                {" "}Applicants’ Signature:
              <form method="post" enctype="multipart/form-data">
              <input
                type="file"
                id="sign"
                name="sign"
                accept="image/png, image/jpeg"
                style={{display:'none'}}
                required 
                onChange={(e) => {
                  handleSignature(e)}}
              />
              <label htmlFor="sign" className="filelabel">{" "}Choose file </label>
               {application.Signature && <label>{application.Signature}</label>}
              </form>
            </div>
          <p>
            <b>Note:</b> JSS Academy of Higher Education and Research
            (Mauritius) reserves the right not to run the above programme.
          </p>
          <div>
            <h5 style={{ color: "red" }}>{nameIsseText}{sissueTextUpload}</h5>
          </div>
        </form>
        <div class="buttonContainer">
            <button class="ui button" 
             onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
      </Container>
    );
  }

  let handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);

  return (
    <div style={{
        height: "100%",
        width: "100%",
        overflowY: "auto",
        padding: "30px",
        margin: "0px",
      }}  >
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <UserHeader />
      {spin === true ? (
         <Backdrop className={classes.backdrop} open={spin}>
         <CircularProgress
          style={{
            position: "fixed",
            top: "48%",
            left: "48%",
            background: "transparent",
            zIndex: "2",
          }}
        />
       </Backdrop>
       
      ) : null}
      <div style={{ zIndex: "-2" }}>
        <h1>Application</h1>
        <div className={ApplicationStyle.management}>
          <div className={ApplicationStyle.h1}>
            <h1>
              <strong></strong>
            </h1>
          </div>
          <div>
           <ApplicationFormW />
            {/* <Tab
            panes={panes}
            activeIndex={activeIndex}
            onTabChange={handleTabChange}
          /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
