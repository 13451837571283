import React, { useState, useEffect, useRef } from "react";
import styles from "./ProgramCard.module.scss";
import Feedback from "./feedback.js";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import ProgressBar from "../ProgressBar/ProgressBar";
import Info from "../../assets/svgjs/Info";
import Syllabus from "../../assets/svgjs/Syllabus";
import Announcements from "../../assets/svgjs/Announcements";
import AcademicSchedule from "../../assets/svgjs/AcademicSchedule";
import FeedbackIcon from "../../assets/svgjs/FeedbackIcon";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { blue } from "@material-ui/core/colors";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";

const ProgramCard = ({ Value }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  let userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      paddingTop: "20px",
      height: "60vh",
      overflowY: "auto",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      border: "none",
      transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },

    tooltipFull: {
      fontSize: 30,
    },
    bottomIcons: {
      color: "#000",
      "&:hover": {
        color: "#777",
      },
    },
    volumeButton: {
      color: "#000",
      fontSize: 40,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#777",
        transform: "scale(1.03)",
        transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
      },
    },
    volumeButtonExit: {
      color: "lightgray",
      fontSize: 40,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#777",
        transform: "scale(1.03)",
        transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
      },
    },
  }));
  const classes = useStyles();
  function titleclick() {
    let pdata = userDetails.data.bpdata;
    let sdata = { ...userDetails };
    //sdata.location = {from:"my",title:"MBA"}
    for (let i = 0; i < pdata.length; i++) {
      if (Value.bpid == pdata[i].bpid) {
        sdata.curprg = pdata[i];
      }
    }

    dispatch(awsSignIn(sdata));

    navigate("/MyPrograms/programs");
  }
  function seta(a) {
    let sdata = { ...userDetails };
    sdata.pgcdactive = {
      a: a,
      pid: Value.pid,
      bpid: Value.bpid,
    };
    dispatch(awsSignIn(sdata));

    // navigate("/myPrograms/programinfo");
  }

  const handleClose = () => {
    setOpen(false);
  };
  function modallfeed() {
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            {/* <div
              style={{
                display: "flex",
                height: "80%",
                backgroundColor: "white",
                width: "50%",
              }}
            > */}
            {/* <div style={{width:"100%",height:"80px"}}>
            <span style={{float:"right",cursor:"pointer",color:"black"}} onClick={() => {handleClose()}}>x</span>
          </div> */}

            <Feedback handleClose={handleClose} Value={Value} />
            {/* </div> */}
          </Fade>
        </Modal>
      </>
    );
  }
  return (
    <>
      <div className={styles.program}>
        {modallfeed()}
        <div
          onClick={titleclick}
          className={styles.program__img}
          style={{
            cursor: "pointer",
            backgroundImage: `url('https://${
              Constants.DOMAIN
            }/${config.aws_org_id.toLowerCase()}-resources/images/program-images/${
              Value.pid
            }.png')`,
          }}
        ></div>
        <div className={styles.program__details}>
          <div
            className={styles.h1}
            style={{ marginBottom: "14px", paddingLeft: "20px" }}
          >
            <h1
              onClick={titleclick}
              style={{ color: "blue", cursor: "pointer", fontSize: "20px" }}
            >
              <strong style={{ color: "#01498E" }}>{Value.ptitle}</strong>
            </h1>
          </div>
          <div className={styles.program_info__container}>
            <div className={styles.program_info}>
              <div className={styles.individual_info}>
                <Link
                  to="/myPrograms/programinfo"
                  onClick={() => {
                    seta(0);
                  }}
                >
                  <span>
                    <Info
                      className={styles.icon_size}
                      cls1={styles.info_cls_1}
                      cls2={styles.info_cls_2}
                    />
                  </span>
                  <span style={{ fontSize: "15px", color: "#01498E" }}>
                    {" "}
                    Program info
                  </span>
                </Link>
                <Link
                  to="/myPrograms/programinfo"
                  style={{ paddingRight: "7.4rem" }}
                  onClick={() => {
                    seta(4);
                  }}
                >
                  <span>
                    <Syllabus
                      className={styles.icon_size}
                      cls1={styles.Syb_cls_1}
                    />
                  </span>
                  <span
                    style={{
                      fontSize: "15px",
                      marginLeft: "0px",
                      color: "#01498E",
                    }}
                  >
                    {" "}
                    Syllabus
                  </span>
                </Link>
              </div>
              <div className={styles.individual_info}>
                <Link
                  to="/myPrograms/programinfo"
                  onClick={() => {
                    seta(5);
                  }}
                >
                  <span>
                    <Announcements
                      className={styles.icon_size}
                      cls1={styles.Ans_cls_1}
                    />
                  </span>
                  <span style={{ fontSize: "15px", color: "#01498E" }}>
                    {" "}
                    Announcements
                  </span>
                </Link>
                <Link
                  to="/myPrograms/programinfo"
                  onClick={() => {
                    seta(6);
                  }}
                >
                  <span>
                    <AcademicSchedule
                      className={styles.icon_size}
                      cls1={styles.AS_cls_1}
                      cls2={styles.AS_cls_2}
                      cls3={styles.AS_cls_3}
                      cls4={styles.AS_cls_4}
                    />
                  </span>
                  <span style={{ fontSize: "15px", color: "#01498E" }}>
                    Academic schedule
                  </span>
                </Link>
              </div>
              <div className={styles.individual_info}>
                <div
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <span>
                    <FeedbackIcon
                      className={styles.icon_size}
                      cls1={styles.info_cls_1}
                      cls2={styles.info_cls_2}
                    />
                  </span>
                  <span
                    style={{
                      fontSize: "15px",
                      color: "#01498E",
                      cursor: "pointer",
                      // marginTop: "10px",
                      // textDecoration: "underline",
                    }}
                  >
                    {" "}
                    Overall Program Feedback
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.time_info}>
              {/* for only prod */}
              <p style={{ fontSize: "15px" }}>Duration: {Value.pdur}</p>
              <p style={{ fontSize: "15px" }}>
                Start date:{" "}
                {Value.sd != undefined
                  ? Value.sd.toString().length === 10
                    ? moment(Value.sd * 1000).format("DD MMM YYYY")
                    : moment(Value.sd).format("DD MMM YYYY")
                  : null}{" "}
              </p>
              {/* for only prod */}
              {/* for only dev */}
              {/* <div style={{ fontSize: "15px", color: "#01498E" }}>
              <a href={`https://${
              Constants.DOMAIN
            }/${config.aws_org_id.toLowerCase()}-resources/pdf/ppr/${
              Value.pid
            }.pdf`} target="_blank">PROGRAMME PROJECT REPORT</a>
              </div> */}
              {/* for only dev */}
            </div>
          </div>
          <div className={styles.width}>
            <ProgressBar color="orange" percent={Value.per ?? 0} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgramCard;
