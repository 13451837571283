import "./Formative.scss";
import { TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useState, useEffect, useRef } from "react";
import useWindowDimensions from "../../../modules/Window/Window";
import Modal from "@material-ui/core/Modal";
import Image from "react-bootstrap/Image";

import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import FolderIcon from "@material-ui/icons/Folder";
import Avatar from "@material-ui/core/Avatar";
import { formRow } from "aws-amplify";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
const MiniAssignment = (props) => {
  let {
    setEditmini,
    editmini,
    getAssignmentDataPost1,
    autoScorCal,
    miniloader,
    setMiniloader,
    saveResponse,
    Button,
    imgUrl,
    produce,
    classes,
    getQuiz,
    btnClick,
    setScore,
    curObject,
    showScore,
    miniScore,
    sMiniScore,
    assignment,
    handleClose,
    quizQuestion,
    setShowScore,
    isSubmittingg,
    setQIsLoading,
    assignmentLoad,
    currentQuestion,
    setAssignmentLoad,
    setCurrentQuestion,
    handleImageChange,
    setQuizQuestion,
    totalminiScore,
    handleMiniDesOption,
    answerNext,
    setAnsFcount,
    ansFcount,
    setCheckbox,
    miniopen,
    setMiniopen,
    correct,
    answerPrev,
  } = props;

  const { height, width } = useWindowDimensions();
  const [data, setData] = useState([]);
  const [obj, setObj] = useState();
  const [showSubmiteed, setShowSubmitted] = useState(false);

  const [errors, setErrors] = useState([]);
  const hiddenFileInput = React.useRef(null);

  useEffect(() => {
    console.log("Mounted EditMiniAssignment Quize");
  }, []);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  function confirmSave() {
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalConfirm}
          open={miniopen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={miniopen}>
            <div className={classes.paper}>
              <h3 style={{ padding: "33px 33px 0px 63px", fontSize: "20px" }}>
                Are you sure you want to submit?
              </h3>
              <button
                style={{
                  position: "relative",
                  right: "40px",
                  top: "70px",
                  color: "#3372B5",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  fontSize: "15px",
                  height: "28px",
                  lineHeight: "13px",
                  float: "right",
                  width: "100px",
                  border: "2px solid #3372B5",
                }}
                onClick={() => {
                  setMiniloader(true);
                  if (
                    obj.miniassignmenttype != undefined &&
                    (obj.miniassignmenttype == "1" ||
                      obj.miniassignmenttype == 1)
                  ) {
                    autoScorCal(data, obj);
                  } else {
                    saveResponse(data, obj);
                  }
                }}
              >
                Confirm
              </button>
              <br />
              <button
                style={{
                  position: "relative",
                  right: "-28px",
                  top: "55px",
                  color: "lightcoral",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  fontSize: "15px",
                  height: "28px",
                  lineHeight: "13px",
                  float: "left",
                  width: "100px",
                  border: "2px solid lightcoral",
                }}
                onClick={() => {
                  // setBtnClick(false);
                  //getQuiz(curObject);
                  /*
                setScore(0);
                setShowScore(false);
                setCurrentQuestion(0); */
                  setMiniopen(false);
                  setScore(0);
                  setShowScore(true);
                  setCurrentQuestion(0);
                }}
              >
                Cancel
              </button>
            </div>
          </Fade>
        </Modal>
      </>
    );
    const handleClosePop = () => {
      setMiniopen(false);
    };
  }

  return (
    <div
      className="body"
      style={{ height: btnClick || showScore ? height - 400 : "" }}
    >
      {confirmSave()}
      {editmini ? (
        <div className="app1">
          {showSubmiteed ? (
            <div className="score-section">
              <Backdrop className={classes.backdrop} open={miniloader}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <h3>Goto Home Page</h3>
              <div>
                <button
                  className="buttonQuizCt"
                  onClick={() => {
                    handleClose();
                    setScore(0);
                    setCurrentQuestion(0);
                    getQuiz(curObject);
                  }}
                >
                  Exit Assignment
                </button>
              </div>
            </div>
          ) : (
            <div className="scrollQuiz">
              <div className="question-section">
                <div className="question-count">
                  <span>Question {currentQuestion + 1}</span>/
                  {quizQuestion.length}
                </div>
                <div
                  className="question-text"
                  dangerouslySetInnerHTML={{
                    __html: quizQuestion[currentQuestion].istem,
                  }}
                >
                  {/* {quizQuestion[currentQuestion].istem} */}
                </div>
              </div>
              {
                <div>
                  {!quizQuestion[currentQuestion].iopts ||
                  quizQuestion[currentQuestion].iopts.length === 0 ? (
                    <div className={classes.root}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: `${
                            quizQuestion[currentQuestion].imageurl
                              ? "4fr"
                              : "1fr"
                          } `,
                          gridGap: "1.5rem",
                        }}
                      >
                        <center>
                          <div style={{ width: "auto" }}>
                            {quizQuestion[currentQuestion].imageurl ? (
                              <div className="img_container">
                                <img
                                  src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`}
                                  alt="item"
                                />
                              </div>
                            ) : null}
                          </div>
                        </center>
                        <br></br>

                        <div className={classes.root}>
                          <TextField
                            fullWidth
                            className={classes.textarea11}
                            value={
                              quizQuestion[currentQuestion].response ==
                                undefined ||
                              quizQuestion[currentQuestion].response.length == 0
                                ? ""
                                : quizQuestion[currentQuestion].response
                            }
                            placeholder="Type your response here..."
                            multiline
                            rows={4}
                            name="response"
                            label="Insert some text"
                            onChange={(e) => {
                              setQuizQuestion((currentQues) =>
                                produce(currentQues, (v) => {
                                  v[currentQuestion].response = e.target.value;
                                })
                              );
                              console.log(
                                "i come",
                                JSON.stringify(quizQuestion[currentQuestion])
                              );
                            }}
                            variant="outlined"
                            color="primary"
                          />
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "2fr 2fr 1fr",
                              gridGap: "1.5rem",
                            }}
                          >
                            <div
                              style={{
                                width: "65%",
                                padding: "5%",
                                fontSize: "14px",
                              }}
                            >
                              <p>
                                <span style={{ fontWeight: "bold" }}>
                                  Note:
                                </span>{" "}
                                Please upload files in the format of pdf, jpg,
                                jpeg, png only.
                              </p>
                            </div>
                            <div style={{ paddingTop: "5%", fontSize: "14px" }}>
                              <input
                                className={classes.hidden}
                                // id={files.name}
                                // name={files.name}
                                accept="image/*,application/pdf"
                                type="file"
                                onChange={handleImageChange}
                                ref={hiddenFileInput}
                                style={{ display: "none" }}
                              />

                              <Avatar
                                style={{
                                  background: "#3f51b5",
                                  height: "56px",
                                  width: "56px",
                                }}
                                onClick={handleClick}
                              >
                                <FolderIcon />
                              </Avatar>
                              <Typography
                                className={classes.title}
                                variant="h6"
                              ></Typography>
                              <p
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  paddingLeft: "3%",
                                }}
                              >
                                {quizQuestion[currentQuestion]
                                  .uploadedfilename == undefined ||
                                quizQuestion[currentQuestion].uploadedfilename
                                  .length == 0
                                  ? "Browse"
                                  : quizQuestion[currentQuestion]
                                      .uploadedfilename}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            marginTop: "-1%",
                            paddingLeft: "3%",
                            paddingRight: "3%",
                          }}
                        >
                          {currentQuestion > 0 ? (
                            <Button
                              color="primary"
                              variant="contained"
                              fullWidth
                              className="btn-size"
                              onClick={() => {
                                answerPrev();
                              }}
                              style={{ fontSize: "12px" }}
                            >
                              Prev
                            </Button>
                          ) : null}
                          {currentQuestion + 1 === quizQuestion.length ? (
                            <Button
                              disabled={
                                quizQuestion[currentQuestion].response ==
                                  undefined ||
                                (quizQuestion[currentQuestion].response
                                  .length == 0 &&
                                  isSubmittingg == true)
                              }
                              color="primary"
                              variant="contained"
                              fullWidth
                              className="btn-siz"
                              onClick={() => {
                                setData(quizQuestion);
                                setObj(curObject);
                                setMiniopen(true);
                                setShowSubmitted(true);
                                // confirmSave(quizQuestion,curObject)

                                // saveResponse(quizQuestion,curObject)
                              }}
                              style={{ float: "right", fontSize: "12px" }}
                            >
                              {isSubmittingg ? "Loading..." : "Submit"}
                            </Button>
                          ) : (
                            <Button
                              disabled={
                                quizQuestion[currentQuestion].response ==
                                  undefined ||
                                quizQuestion[currentQuestion].response.length ==
                                  0 ||
                                isSubmittingg == true
                              }
                              color="primary"
                              variant="contained"
                              fullWidth
                              className="btn-siz"
                              onClick={() => {
                                answerNext();
                              }}
                              style={{ float: "right", fontSize: "12px" }}
                            >
                              {isSubmittingg ? "Loading..." : "Next"}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    "null"
                  )}
                </div>
              }
            </div>
          )}
        </div>
      ) : (
        <div>
          {sMiniScore ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <h1
                style={{
                  color: "#000",
                  textAlign: "center",
                  margin: "2.5rem 0",
                  boxShadow: "0 0 0 5px #e35f14",
                  borderRadius: "100%",
                  height: "100px",
                  width: "100px",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {miniScore}/{totalminiScore}
                </span>
              </h1>
            </div>
          ) : null}

          {assignmentLoad ? (
            <CircularProgress color="default" size={30} />
          ) : navigator.onLine ? (
            <button
              className="btnQuizLD"
              style={{ width: "fit-content" }}
              onClick={() => {
                getAssignmentDataPost1(curObject.oid);
                setQIsLoading(true);
                setAssignmentLoad(true);
              }}
            >
              Edit Assignment
            </button>
          ) : (
            <p>you are offline</p>
          )}
        </div>
      )}
    </div>
  );
};

export default MiniAssignment;
