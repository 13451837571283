import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, Link } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";

import { awsSignIn, authData, awsSignOut } from "../redux/auth/authSlice";
import config from "../config/aws-exports";
import { Constants } from "../config/constants";

import LandingScreen from "../components/LandingScreen/LandingScreen";

//import Constants from '../config/constants';
//import logo from './logo.svg';

import EventLayout from "../Layouts/EventLayout";
// import IndividualProgram from "../components/MyPrograms/IndividualProgram";

import MyApplicationLayout from "../Layouts/MyApplicationLayout";
import MyProgramsPreLogin from "../components/MyProgramsPreLogin/MyProgramsPreLogin";

import "./App.scss";
import Layout from "../components/HomeScreen/Dashboard/Layout";
import MyProgramsLayout from "../Layouts/MyProgramsLayout";
import CourseLayout from "../Layouts/CourseLayout";
import CoursePlayerLayout from "../Layouts/CoursePlayerLayout";
import IndividualProgramLayout from "../Layouts/IndividualProgramLayout";
import NotificationLayout from "../Layouts/NotificationLayout";
import ReportsAndAnalyticsLayout from "../Layouts/ReportsAndAnalyticsLayout";
import SyllabusLayout from "../Layouts/SyllabusLayout";
import AnnouncementsLayout from "../Layouts/AnnouncementsLayout";
import AcademicScheduleLayout from "../Layouts/AcademicScheduleLayout";
import ProgramInfoLayout from "../Layouts/ProgramInfoLayout";
import OnlineProgram from '../modules/TopNav_StaticPages/Admissions/OnlineProgram';
import AboutJSSAHER from '../modules/TopNav_StaticPages/AboutUs/AboutJSSAHER';
import AboutOnlineLearning from '../modules/TopNav_StaticPages/AboutUs/OnlineLearning';
import ContactUs from '../modules/TopNav_StaticPages/ContactUs/ContactUs'
import Application from "../components/MyApplication/Application";
import AllPrograms from "../components/AllPrograms/AllPrograms";
import AllProgramsLayout from "../Layouts/AllProgramsLayout";
import AllProgramsInfoLayout from '../Layouts/ApplyProgramInfoLayout'
import ApplicationLayout from "../Layouts/ApplicationLayout";
import QuickTourPreLogin from '../components/QuickTour/QuickTourPreLogin';
import QuickTourLayout from "../Layouts/QuickTourLayout";
import ApplicationPre from '../components/MyProgramsPreLogin/ApplicationPre';
import MauritiusApplicationPre from "../components/MyProgramsPreLogin/MauritiusApplicationPre";
function App() {
  const [api, setApi] = useState([{}]);
  const [user, setUser] = useState("");
  const userAuthStateValue = useSelector(authData);
  const dispatch = useDispatch();

  useEffect(() => {

    return () => { };
  }, []);


  async function signIn() {
    try {
      const user = await Auth.signIn("sumana@enhanzed.com", "qwerty@1");

      const info = await Auth.currentUserInfo();
      let userdata = user.attributes;
      userdata.username = user.username;
      userdata.id = info.id;
      setUser(JSON.stringify(userdata));
      dispatch(awsSignIn(userdata));
    } catch (error) {
      console.log("error signing in", error);
    }
  }

  function Home() {
    return (
      <div>
        <button onClick={signIn}>Login</button>
        <pre>{JSON.stringify(userAuthStateValue, null, 2)}</pre>
        <button
          onClick={() => {
            Auth.signOut();
            dispatch(awsSignOut());
          }}
        >
          signOut
        </button>
        <h1>Home</h1>
        <nav>
          <Link to="/">Home</Link>
          {" | "}
          <Link to="/LandingScreen">LandingScreen</Link>
          {" | "}
          <Link to="/about">About</Link>
          {" | "}
          <Link to="/dashboard">Dashboard </Link>
          {" | "}
          <Link to="/event">Events </Link>
          {" | "}
          <Link to="/MyPrograms/programs">Programs </Link>
          {" | "}
          <Link to="/myPrograms">Myprograms</Link>
          {" | "}
          <Link to="/course">Course</Link>
          {" | "}
          <Link to="/myProg">PreLogin</Link>
          {" | "}
          <Link to="/event">Event</Link>
        </nav>

        <div
          style={{ padding: 20, height: 300, width: "100%", overflow: "auto" }}
        ></div>
      </div>
    );
  }

  function About() {
    return (
      <div>
        <button
          onClick={() => {
            Auth.signOut();
            dispatch(awsSignOut());
          }}
        >
          signOut
        </button>
        <h1>About</h1>
        <nav>
          <Link to="/">Home</Link> {" | "}
          <Link to="/LandingScreen">LandingScreen</Link> {" | "}
          <Link to="/about">About</Link>
        </nav>
      </div>
    );
  }

  return (
    <div className="App">
      {/* <Offline>
        <div style={{width:'100%',height:'100%',position:'fixed',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center', zIndex:'2222',background:'#000000a1',color:'white',fontSize:'100px'}}>
          <div>
            Please check your internet connection!
          </div>
        </div>
      </Offline> */}
      <Routes>
        {userAuthStateValue === 0 ? (
          <>

            <Route path="/" element={<LandingScreen />} />
            <Route path="*" element={<LandingScreen />} />
            <Route path="/onlineProgram" element={<OnlineProgram />} />
            <Route path="/aboutJssaher" element={<AboutJSSAHER />} />
            <Route path="/aboutOEP" element={<AboutOnlineLearning />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/myProg/:pid/:il" element={<MyProgramsPreLogin />} />
            <Route path="/quickTourPreLogin" element={<QuickTourPreLogin />} />
            <Route path="/application" element={<ApplicationPre />} />
            <Route path="/marApplicationPre" element={<MauritiusApplicationPre />} />
          </>
        ) : (
          <>

            <Route path="/quickTour" element={<QuickTourLayout />} />
            <Route path="/" element={<Layout />} />
            <Route path="/dashboard" element={<Layout />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/onlineProgram" element={<OnlineProgram />} />
            <Route path="/aboutJssaher" element={<AboutJSSAHER />} />
            <Route path="/aboutOEP" element={<AboutOnlineLearning />} />
            <Route path="/LandingScreen" element={<LandingScreen />} />
            <Route path="about" element={<About />} />

            {/* <Route path="/event" element={<Event />} /> */}
            <Route
              path="/MyPrograms/programs"
              element={<IndividualProgramLayout />}
            />

            <Route path="/myPrograms" element={<MyProgramsLayout />} />
            <Route path="/course" element={<CourseLayout />} />
            <Route path="/coursePlayer" element={<CoursePlayerLayout />} />
            <Route path="/myApplication" element={<MyApplicationLayout />} />
            <Route path="/myProg" element={<MyProgramsPreLogin />} />
            <Route path="/event" element={<EventLayout />} />
            <Route path="/notification" element={<NotificationLayout />} />
            <Route path="/reportsAndAnalytics" element={<ReportsAndAnalyticsLayout />} />
            <Route path="myPrograms/programinfo" element={<ProgramInfoLayout />} />
            <Route path="/myPrograms/syllabus" element={<SyllabusLayout />} />
            <Route path="/myPrograms/announcements" element={<AnnouncementsLayout />} />
            <Route path="/myPrograms/academicSchedule" element={<AcademicScheduleLayout />} />
            {/* // <Route path="/myApplication/application" element={<Application />} /> */}
            <Route path="/allPrograms" element={<AllProgramsLayout />} />
            <Route path="/applyProgram" element={<AllProgramsInfoLayout />} />
            <Route path="/myApplication/application" element={<ApplicationLayout />} />
            <Route path="*" element={<Layout />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;

// import React from 'react';

// import Layout from '../Layout';

// import './App.scss';

// function App() {
//   return (
//     <div>
//       <Layout />
//     </div>
//   );
// }

// export default App;
