import React from 'react';
import Footer from '../../../components/Footer/Footer';
import AppHeader from '../../../components/Header/AppHeader';
import './ContactUs.scss'
import { Link } from "react-router-dom";
function ContactUs() {

    return (
        <div>
            <AppHeader />
            <div align="left" style={{ padding: "5% 20% 13% 20%" }}>
                <Link to={`/`} >
                    <p style={{ color: "#01498e", fontSize: "small", marginBottom: "30px", marginTop: "10px" }}> {`< Back To Home`}</p>
                </Link>

                <p className="hedingStyle">Contact us</p>
                <div className="liststyle">


                    {/* <p className="programTitle"><strong>Registrar, </strong></p> */}
                    <p className="programTitle">JSSAHER Mauritius</p><br />



                    <p className="institutionName"><strong>JSS Medical Institutions Campus</strong><br />
                        Droopnath Ramphul Avenue, <br />
                        Bonne Terre, Vacoas,<br />
                        Republic of Mauritius.<br /><br />

                        Phone: +230 401 6415<br /><br />

                        Email-ID: info@jssaher.edu.mu
                    </p>


                </div>

            </div>














            <Footer />
        </div>
    )
}
export default ContactUs;