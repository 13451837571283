import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import { Box, Button, Paper } from '@material-ui/core';



const labels = {
    1: 'Not Applicable',
    2: 'Strongly Disagree',
    3: 'Disagree',
    4: 'Agree',
    5: 'Strongly Agree',
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    },
}));

export default function FeedBack({ close, Questions, cri, feedbackF, cinstr }) {
    const [value, setValue] = useState(-1);
    const [hover, setHover] = useState(-1);
    const [bstatus, setBstatus] = useState(true);
    const [answer, setAnswer] = useState({});
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const classes = useStyles();

    function nextClick() {
        if((currentQuestion < Questions.length - 1) && value != -1 ){
            let t = {...answer};
            t[`q${currentQuestion + 1}`] = value;
            // alert(JSON.stringify(t));
            setAnswer(t);
            setCurrentQuestion(currentQuestion + 1);
            setValue(-1);
            setHover(-1);
            setBstatus(true);
        }else if((currentQuestion == Questions.length - 1) && value != -1){
            let t = {...answer};
            t[`q${currentQuestion + 1}`] = value;
            setAnswer(t);
            feedbackF(cri,"update",t,cinstr);
            close(false);
            // alert(JSON.stringify(t));
        }
    }

    return (
        <div className={classes.root}>
            <Paper elevation={3} style={{ height: "62vh", width: "70vw", overflowY:"scroll" }} >
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", height: "50px", marginRight: "15px" }}> <span style={{ fontSize: "20px", fontWeight: "bold", cursor: "pointer" }} onClick={() => { close(false); }}>X</span> </div>
                <div style={{
                    marginLeft: "50px",
                    marginRight: "40px",
                }}>
                    <h2
                        style={{
                            //   marginLeft: "50px",
                            //   paddingTop: "20px",
                            paddingBottom: "15px",
                        }}
                    >
                        Dear Learner, Your feedback is extremely valuable for the
                        University.It helps the University to improve its academic offerings
                        continuously.
                    </h2>
                    <h2 style={{ paddingBottom: "15px" }}>
                        <span>Question {currentQuestion + 1}</span>/
                        {Questions.length}
                    </h2>
                    {cinstr != "" ? 
                    <h2 style={{ paddingBottom: "15px" }}>
                        Feedback for Instructor - {cinstr.name}
                    </h2>:""}
                    <div style={{ fontSize: "22px", textAlign: "center", marginRight: "30px" }}>
                        {currentQuestion + 1}. {Questions[currentQuestion]}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px", flexDirection: "column" }}>

                        <Rating
                            name="hover-feedback"
                            value={value}
                            precision={1}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                                if (newValue == null || newValue == undefined || newValue == -1) {
                                    setBstatus(true);
                                } else {
                                    setBstatus(false);
                                }
                                
                            }}
                            onChangeActive={(event, newHover) => {
                                setHover(newHover);
                            }}
                        />
                        <div>
                            {<Box ml={2} style={{ fontSize: "16px", fontWeight: "bold" }}>Response : {labels[hover !== -1 ? hover : value]}</Box>}
                        </div>

                    </div>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button variant="contained" color="primary" onClick={nextClick} disabled={bstatus}>
                           {currentQuestion == Questions.length - 1 ? "Submit":"Next"}
                        </Button>
                    </div>
                </div>

            </Paper>
        </div>
    );
}
