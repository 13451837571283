import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Dropdown, ModalActions } from "semantic-ui-react";
import Tooltip from "@material-ui/core/Tooltip";
import { Document, Page, pdfjs } from "react-pdf";
import ReactPlayer from "react-player";
import { awsSignIn, authData, awsSignOut } from "../../redux/auth/authSlice";
import UserHeader from "../Header/UserHeader/UserHeader";
import useWindowDimensions from "../../modules/Window/Window";
import ProgramCard from "../../modules/ProgramCard/ProgramCard";
// import "./MyPrograms.scss";
import { FaBars } from "react-icons/fa";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";

import { API, button, Auth } from "aws-amplify";
import "./CoursePlayer.scss";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { IconButton } from "@material-ui/core";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Fullscreen from "@material-ui/icons/Fullscreen";

import Fade from "@material-ui/core/Fade";
import screenfull from "screenfull";

import { Formik, useField, useFormik, Form, Field } from "formik";
import CustomImageInput from "../../modules/CustomImageInput/CustomImageInput";
import * as Yup from "yup";
import { valHooks } from "jquery";

import axios from "axios";
import Done from "../../assets/svgjs/Done";
import Reject from "../../assets/svgjs/Reject";
import produce from "immer";
import Formative from "../../components/CourseStructure/Quize/Formative";
import FormativeFeedback from "../../components/CourseStructure/Quize/FormativeFeedback";
import Summative from "../../components/CourseStructure/Quize/Summative";
import MiniAssignment from "../../components/CourseStructure/Quize/MiniAssignment";
import EditMiniAssignment from "../../components/CourseStructure/Quize/EditMiniAssignment";

const CoursePlayer = (props) => {
  let {
    handleClose,
    totalminiScore,
    courseDetails,
    setCourseDetails,
    objectData,
    setObjectData,
    oIndex,
    setOIndex,
    curObject,
    setcurObject,
    curObRef,
    assignment,
    setAssignment,
    summative,
    sumScore,
    setSumScore,
    miniScore,
    setMiniScore,
    sMiniScore,
    setSMiniScore,
    qisLoading,
    setQIsLoading,
    getQuizScore,
    getAssignmentDataPost,
    assignmentLoad,
    setAssignmentLoad,
  } = props;

  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [quizQuestion, setQuizQuestion] = useState([]);
  const [btnClick, setBtnClick] = useState(false);
  const [qtype, setQtype] = useState("");
  const [answerReveal, setAnswerReveal] = useState(false);
  const [popUp, setPopup] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const formData = useRef([]);
  const full = useRef(null);
  const [settingnull, setSettingnull] = useState(0);
  const [miniopen, setMiniopen] = useState(false);

  const { height, width } = useWindowDimensions();
  const [fullscreen, setFullScreen] = useState(true);
  const [tryAgain, setTryAgain] = useState(false);
  const [formativeAns, setFormativeAns] = useState([]);
  const [isSubmittingg, setSubmittingg] = useState(false);
  const [cutOff, setCutOff] = useState("");
  const [prevData, setPrevData] = useState(false);
  const [ansFcount, setAnsFcount] = useState(0);
  const [correct, setCorrect] = useState();

  const [checkboxes, setCheckboxes] = useState([]);

  const [feedbackButton, setFeedbackButton] = useState({
    disableSubmit: true,
    hideNext: true,
    hideSubmit: false,
    submitted: false,
  });

  // const courseDetails = useRef({});
  // const objectData = useRef({});
  const [editmini, setEditmini] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [markObjectAsComplete, setMarkObjectAsCompleted] = useState(false);
  const [open, setOpen] = useState(false);
  const [miniloader, setMiniloader] = useState(false);
  const submitScore = useRef(0);
  let h = true;
  // const [courseDetails, setCourseDetails] = useState({});
  // const [objectData, setObjectData] = useState({});
  // const [oIndex, setOIndex] = useState(0);
  // const [curObject, setcurObject] = useState({});

  const startTime = useRef(0);

  let userDetails = useSelector(authData);
  let objData = userDetails.curprgcouobj;
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        color: "black !important",
      },

      "& .MuiFormLabel-root": {
        color: "black !important",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    textarea11: {
      "& .MuiInputBase-input": {
        color: " #black !important",
        fontSize: "12.5px !important",
      },
    },
    paper: {
      backgroundColor: theme.palette.background.paper,

      height: "200px",
      width: "500px",
      borderRadius: "4px",
      border: "none",
      padding: theme.spacing(2, 4, 3),
    },
    modalConfirm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      border: "none",
      transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },

    tooltipFull: {
      fontSize: 30,
    },
    bottomIcons: {
      color: "#000",
      "&:hover": {
        color: "#777",
      },
    },
    volumeButton: {
      color: "#000",
      fontSize: 40,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#777",
        transform: "scale(1.03)",
        transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
      },
    },
    volumeButtonExit: {
      color: "lightgray",
      fontSize: 40,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#777",
        transform: "scale(1.03)",
        transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    //alert(JSON.stringify(userDetails.curprgcouobj))

    startTime.current = Math.round(new Date().getTime());
    setLoaded(true);
    // courseDetails.current = objData.course;
    // objectData.current = objData.objects;

    // setCourseDetails(objData.course);
    // setObjectData(objData.objects);

    //getCourse();
    //setOIndex(userDetails.oindex);
  }, []);

  async function getCourse() {
    let lcourseDetails = userDetails.curprgcou;
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        pid: lcourseDetails.pid,
        bpid: lcourseDetails.bpid,
        courseid: lcourseDetails.tid,
        bcourseid: lcourseDetails.bcid,
        ptitle: lcourseDetails.ptitle,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    //alert(JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_COURSE,
        bodyParam
      );
      //alert(JSON.stringify(response));
      const topicsJSON = response.nuggets;
      function groupByKey(array, key) {
        return array.reduce((hash, obj) => {
          if (obj[key] === undefined) return hash;
          return Object.assign(hash, {
            [obj[key]]: (hash[obj[key]] || []).concat(obj),
          });
        }, {});
      }
      var result = groupByKey(topicsJSON, "unit");
      let temp = [];
      for (let i = 0; i < topicsJSON.length; i++) {
        for (let j = 0; j < topicsJSON[i].objects.length; j++) {
          temp.push(topicsJSON[i].objects[j]);
        }
      }

      // alert(JSON.stringify(response));
      // alert(JSON.stringify(userDetails.oindex));
      console.log(response);
      setCourseDetails(response);
      setObjectData(temp);
      setcurObject(temp[userDetails.oindex]);
      curObRef.current = temp[userDetails.oindex];
      setLoaded(true);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }
  async function getQuiz(obj) {
    console.log(obj);
    setAnsFcount(0);
    setAssignmentLoad(true);
    setQIsLoading(true);
    const bodyParam = {
      body: {
        quizid: obj.oid,
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_QUIZ,
        bodyParam
      );

      const { qitems, qtype } = response;
      // console.log("cutoff" + cutoff);
      setQtype(qtype);
      setQuizQuestion(qitems);
      response.cutoff === undefined
        ? (response.cutoff = qitems.length / 2)
        : setCutOff(response.cutoff);
      // if (response.cutoff == undefined) {
      //   let temp = qitems.length / 2;
      //   // let tempcutof =
      //   setCutOff(temp);
      // } else {
      //   setCutOff(response.cutoff);
      // }
      setCutOff(response.cutoff);
      // alert(response.cutoff);
      setBtnClick(true);
      setQIsLoading(false);
      setAssignmentLoad(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  async function syncUserProgress(userProgressData, obj1) {
    let lcourseDetails = userDetails.curprgcou;
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        pid: lcourseDetails.pid,
        bpid: lcourseDetails.bpid,
        courseid: lcourseDetails.tid,
        bcourseid: lcourseDetails.bcid,
        ptitle: lcourseDetails.ptitle,
        courseProgress: userProgressData,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.SYNC_USER_PROGRESS,
        bodyParam
      );
      console.log(response);
      if (obj1 !== undefined) {
        if (obj1.qtype == 3) {
          if (
            obj1.miniassignmenttype == undefined ||
            obj1.miniassignmenttype == 2 ||
            obj1.miniassignmenttype == "2"
          ) {
            setOpen(false);
            setMiniloader(false);
            setShowScore(true);
          }
        }
      }
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  function setMarkComplete() {
    setTimeout(() => {
      console.log("sT " + curObRef.current.userOP.op);
      if (
        curObRef.current.userOP.op == 0 &&
        (courseDetails.freenavigation == "false" ||
          courseDetails.freenavigation == false)
      ) {
        setMarkObjectAsCompleted(false);
      } else if (curObRef.current.userOP.op != 2) {
        let check = checkQuiz(curObRef.current);

        if (check) {
          if (markObjectAsComplete == false) {
            setMarkObjectAsCompleted(true);
          }
        }
      }
      // if (curObRef.current.userOP.op != 2) {
      //   setMarkObjectAsCompleted(true);
      // }
    }, 5000);
  }

  function checkQuiz(obj) {
    if (obj.otype === "quiz") {
    }
    return true;
  }

  function renderObjects() {
    //alert(JSON.stringify(curObject));

    let objType = curObject.otype;
    //alert(objType);
    console.log("s " + JSON.stringify(curObject));
    if (
      curObject.userOP.op == 0 &&
      (courseDetails.freenavigation == "false" ||
        courseDetails.freenavigation == false)
    ) {
      let tobjectData = [...objectData];
      let toIndex = oIndex;
      if (toIndex != 0) {
        if (tobjectData[toIndex - 1].userOP.op == 2) {
          if (markObjectAsComplete == false) {
            setTimeout(() => {
              setMarkObjectAsCompleted(true);
            }, 5000);
          }
        } else {
          return LockView();
        }
      } else {
        return LockView();
      }
      // if(tobjectData[toIndex].userOP.op == 0)
    } else if (curObject.userOP.op != 2) {
      setMarkComplete();
    }
    console.log("otype" + JSON.stringify(objType));
    switch (objType) {
      case "video":
        return VideoView(); // loadVideoView(curObject);
      case "audio":
        return AudioView(); // loadAudioView(curObject);
      case "pdf":
        return PdfView(); // loadMultimediaView(curObject);
      case "html":
        return WebView(); // loadMultimediaView(curObject);
      case "Interactivity":
        return WebView();
      case "interactivity":
        return WebView(); // loadInteractivityView(curObject);
      case "quiz":
        return quizView();
      case "vimeo":
        return VimeoView(); // loadVimeoView(curObject);
      case "youtube":
        return VimeoView(); // loadYoutubeView(curObject);
      default:
        return null;
    }
  }

  function renderButtons() {
    console.log("online", navigator.onLine);
    console.log("render", JSON.stringify(curObject));
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          //paddingTop: "100px",
        }}
      >
        <div>
          {oIndex === 0 ? (
            <div></div>
          ) : navigator.onLine ? (
            <Button
              onClick={prev}
              basic
              content="Prev"
              style={{ fontWeight: "bold", fontSize: "14px " }}
              color="blue"
            />
          ) : null}
        </div>
        <div>
          {markObjectAsComplete == true ? (
            curObject.qtype == 2 || curObject.qtype == 1 ? (
              <div></div>
            ) : navigator.onLine ? (
              <Button
                onClick={markComplete}
                basic
                content="Mark Complete"
                color="blue"
                style={{ fontWeight: "bold", fontSize: "14px " }}
              />
            ) : null
          ) : (
            <div></div>
          )}
        </div>
        <div>
          {curObject.userOP.op == 2 ? (
            navigator.onLine ? (
              <Button
                onClick={next}
                basic
                content="Next"
                style={{ fontWeight: "bold", fontSize: "14px " }}
                color="blue"
              />
            ) : null
          ) : null}
        </div>
      </div>
    );
  }

  function prev() {
    setCurrentQuestion(0);
    setMarkObjectAsCompleted(false);
    setBtnClick(false);
    setTryAgain(true);
    setFormativeAns([]);
    setFeedbackButton({
      disableSubmit: true,
      hideNext: true,
      hideSubmit: false,
      submitted: false,
    });
    console.log("index", oIndex);

    //var newObject = JSON.parse(JSON.stringify(oldObject));
    let tcourseDetails = JSON.parse(JSON.stringify(courseDetails));
    let tobjectData = [...objectData];
    let toIndex = oIndex;

    startTime.current = Math.round(new Date().getTime());

    let l = tobjectData.length - 1;
    if (toIndex > 0) {
      toIndex = toIndex - 1;
      setOIndex(toIndex);
      setcurObject(tobjectData[toIndex]);
      curObRef.current = tobjectData[toIndex];
      if (tobjectData[toIndex].otype === "quiz") {
        getAssignmentDataPost(tobjectData[toIndex].oid);
        getQuizScore(tobjectData[toIndex]);
      }
    }

    saveredux(toIndex);
  }

  function next() {
    setBtnClick(false);
    setFeedbackButton({
      disableSubmit: true,
      hideNext: true,
      hideSubmit: false,
      submitted: false,
    });
    setMarkObjectAsCompleted(false);

    setTryAgain(true);
    setFormativeAns([]);
    setCurrentQuestion(0);

    let tcourseDetails = JSON.parse(JSON.stringify(courseDetails));
    let tobjectData = [...objectData];
    let toIndex = oIndex;

    startTime.current = Math.round(new Date().getTime());

    let l = tobjectData.length - 1;
    if (toIndex < l) {
      toIndex = toIndex + 1;
      setOIndex(toIndex);
      setcurObject(tobjectData[toIndex]);
      curObRef.current = tobjectData[toIndex];
      if (tobjectData[toIndex].otype === "quiz") {
        getAssignmentDataPost(tobjectData[toIndex].oid);
        getQuizScore(tobjectData[toIndex]);
      }
    }
    saveredux(toIndex);
  }

  function markComplete() {
    setMarkObjectAsCompleted(false);
    setBtnClick(false);
    setCurrentQuestion(0);

    let tcourseDetails = JSON.parse(JSON.stringify(courseDetails));
    let tobjectData = [...objectData];
    let toIndex = oIndex;

    let userProgressEndTime = Math.round(new Date().getTime());
    let totalTimeTaken = userProgressEndTime - startTime.current;
    totalTimeTaken = totalTimeTaken / 1000;

    tcourseDetails.userProgressData[tcourseDetails.btid].objects[
      tobjectData[toIndex].oid
    ].op = 2;

    tobjectData[toIndex].userOP.op = 2;

    tcourseDetails.userProgressData[tcourseDetails.btid].objects[
      tobjectData[toIndex].oid
    ].timespent = totalTimeTaken;
    // tcourseDetails.userProgressData[tcourseDetails.btid].objects[
    //   tobjectData[toIndex].oid
    // ].edited = 1;
    tobjectData[toIndex].userOP.timespent = totalTimeTaken;

    let l = tobjectData.length - 1;
    if (toIndex < l) {
      toIndex = toIndex + 1;

      if (
        tcourseDetails.userProgressData[tcourseDetails.btid].objects[
          tobjectData[toIndex].oid
        ].op != 2
      ) {
        tcourseDetails.userProgressData[tcourseDetails.btid].objects[
          tobjectData[toIndex].oid
        ].op = 1;
        tobjectData[toIndex].userOP.op = 1;
      }

      setOIndex(toIndex);
      setcurObject(tobjectData[toIndex]);

      if (tobjectData[toIndex].otype === "quiz") {
        getAssignmentDataPost(tobjectData[toIndex].oid);
        getQuizScore(tobjectData[toIndex]);
        console.log("formdata", formData.current);
        formData.current = [];
        // getQuiz(curObject);
        // getQuizScore(tobjectData[toIndex]);
      }

      curObRef.current = tobjectData[toIndex];
    } else if (toIndex == l) {
      if (
        tcourseDetails.freenavigation == "true" ||
        tcourseDetails.freenavigation == true
      ) {
        tcourseDetails.userProgressData[tcourseDetails.btid].tp = 2;
        // pushCustomAnalytics('complete');
      } else {
        let length = 0;
        for (let i = 0; i < tobjectData.length; i++) {
          if (tobjectData[i].userOP.op == 2) {
            length++;
          } else if (tobjectData[i].userOP.op == 0) {
            tcourseDetails.userProgressData[tcourseDetails.btid].objects[
              tobjectData[i].oid
            ].op = 1;
            tobjectData[i].userOP.op = 1;
          }
        }
        if (length == tobjectData.length) {
          tcourseDetails.userProgressData[tcourseDetails.btid].tp = 2;
          //pushCustomAnalytics('complete');
        } else {
          tcourseDetails.userProgressData[tcourseDetails.btid].objects[
            tobjectData[toIndex].oid
          ].op = 1;
          tobjectData[toIndex].userOP.op = 1;
          alert(
            "Ongoing Course was edited, Please complete the Course Objects and complete the last object "
          );
        }
      }
    }
    // courseDetails.current = objData.course;
    // objectData.current = objData.objects;

    setCourseDetails(tcourseDetails);
    setObjectData(tobjectData);
    syncUserProgress(tcourseDetails.userProgressData);
    saveredux(toIndex);
  }

  function saveredux(toIndex) {
    let sdata = { ...userDetails };
    sdata.oindex = toIndex;
    dispatch(awsSignIn(sdata));
    //navigate("/coursePlayer");
  }

  function LockView() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <h1>Please Mark Complete previously viewed object.</h1>
        </div>
      </div>
    );
  }
  function unitLockedView() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <h1>This Unit is disabled from Admin</h1>
        </div>
      </div>
    );
  }

  function VideoView() {
    // console.log("o",curObject)
    return (
      <div
        className="player-wrapper"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <ReactPlayer
          url={curObject.ourl}
          controls={true}
          width="100%"
          height={height - 300}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload", //<- this is the important bit
              },
            },
          }}
        />
      </div>
    );
  }

  function AudioView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={curObject.ourl}
            controls={true}
            width="100%"
            height={height - 700}
          />
        </div>
      </div>
    );
  }

  function VimeoView() {
    return (
      <div>
        <div
          className="player-wrapper"
          //  style={{ height: "64vh" }}
        >
          <iframe
            src={
              curObject.otype !== "vimeo"
                ? `https://www.youtube.com/embed/${curObject.embeddedcode}?rel=0&showinfo=0`
                : `https://player.vimeo.com/video/${curObject.embeddedcode}?title=0&loop=0`
            }
            title="youandvimeo"
            controls={true}
            style={{ height: "55vh", width: "100%" }}
          />
        </div>
      </div>
    );
  }

  function youtubeView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={curObject.url}
            controls={true}
            width="calc(100% - 15%)"
            height="calc(100% - 20px)"
          />
        </div>
      </div>
    );
  }

  function PdfView() {
    // alert("pdf");
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          overflowX: "hidden",
        }}
      >
        <iframe src={curObject.ourl} height="200" width="100%"></iframe>
        {/* <Document file={curObject.ourl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document> */}
        {/* <p>Page {pageNumber} of {numPages}</p> */}
      </div>
    );
  }

  function WebView() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          //height: height - 300,
          height: "100%",
          justifyContent: "center",
        }}
      >
        <iframe
          src={curObject.ourl}
          width={"100%"}
          height={"100%"}
          //height={height - 300}
          title="WebView"
        />
      </div>
    );
  }

  function toggleFullScreen() {
    screenfull.toggle(full.current);
  }

  function onFullScreen() {
    setFullScreen(!fullscreen);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // const [green, setGreen] = useState({});
  // const [red, setRed] = useState({});
  if (quizQuestion == undefined) {
    alert("No Quiz Present");
    handleClose();
    return;
  }

  let percent = (score / quizQuestion.length) * 100;

  // responseSave(formData);
  function handleAnswerOption(ques) {
    return ques;
  }

  const getAssignmentDataPost1 = async (quizid) => {
    setAssignmentLoad(true);
    setQIsLoading(true);
    const bodyParam = {
      body: {
        quizid: quizid,
        oid: config.aws_org_id,
        eid: userDetails.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log(bodyParam);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSIGNMENT_DATA,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      // alert('hello');
      setQuizQuestion(response.response);
      // if (response.errorMessage !== undefined) {
      //   setAssignment(true);
      // } else {
      //   if (response.score !== undefined) {
      //     setSMiniScore(true);
      //     // alert(response.score);
      //     setMiniScore(response.score);
      //   }
      //   setAssignment(false);
      // }
      // setAssignmentLoad(true);

      setQIsLoading(false);
      setEditmini(true);
    } catch (error) {
      console.log("getCategoryError", error);
    }
  };

  function miniEditStatusUpdated(obj1) {
    let tcourseDetails = JSON.parse(JSON.stringify(courseDetails));
    let tobjectData = [...objectData];
    let toIndex = oIndex;
    if (
      tcourseDetails.userProgressData[tcourseDetails.btid].objects[
        tobjectData[toIndex].oid
      ].edited != undefined &&
      tcourseDetails.userProgressData[tcourseDetails.btid].objects[
        tobjectData[toIndex].oid
      ].edited == 0
    ) {
      tcourseDetails.userProgressData[tcourseDetails.btid].objects[
        tobjectData[toIndex].oid
      ].edited = 1;
    } else if (
      tcourseDetails.userProgressData[tcourseDetails.btid].objects[
        tobjectData[toIndex].oid
      ].edited != undefined &&
      tcourseDetails.userProgressData[tcourseDetails.btid].objects[
        tobjectData[toIndex].oid
      ].edited == 1
    ) {
      tcourseDetails.userProgressData[tcourseDetails.btid].objects[
        tobjectData[toIndex].oid
      ].edited = 2;
    }
    setCourseDetails(tcourseDetails);
    syncUserProgress(tcourseDetails.userProgressData, obj1);
  }

  async function saveResponse(data, obj1, autoscore) {
    const obj = {};
    setMiniopen(false);
    if (obj1.qtype == 3) {
      setShowScore(true);
      //  if(obj1.miniassignmenttype == 2 ||obj1.miniassignmenttype =='2'){
      //   miniEditStatusUpdated();
      //  }
    }
    if (autoscore != undefined) {
      obj.score = autoscore;
    }
    obj.response = data;
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        quizdata: obj,
        eid: userDetails.eid,
        objid: obj1.oid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log("body param" + JSON.stringify(bodyParam));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPDATE_MINI_ASSIGNMENT,
        bodyParam
        // `${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      if (obj1.qtype == 3) {
        if (
          obj1.miniassignmenttype == undefined ||
          obj1.miniassignmenttype == 2 ||
          obj1.miniassignmenttype == "2"
        ) {
          miniEditStatusUpdated(obj1);
        } else {
          setOpen(false);
          setMiniloader(false);
          setShowScore(true);
        }
      } else {
        setOpen(false);
        setMiniloader(false);
        setShowScore(true);
      }
      console.log(response);
    } catch (error) {
      console.log("getCategoryError", error);
    }
  }

  function autoScorCal(quizAns, obj) {
    var loop = 0;
    let autoscore = 0;
    quizAns.forEach((element) => {
      loop++;
      if (
        element.iopts[element.response].correct == "true" ||
        element.iopts[element.response].correct == true
      ) {
        autoscore += parseInt(element.qscore);
        console.log(element.iopts[element.response].correct);
        console.log(element.qscore);
      }
      if (quizAns.length == loop) {
        saveResponse(quizAns, obj, autoscore);
      }
    });
  }

  // async function autoScorUpdate(autoscore) {
  //   const bodyParam = {
  //       body: {
  //         eid: userDetails.eid,
  //         obid: curObject.oid,
  //         oid: config.aws_org_id,
  //         score: autoscore,
  //       },
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //     };
  //   alert("body param" + JSON.stringify(bodyParam))
  //   // console.log("body param" + JSON.stringify(bodyParam));
  //   try {
  //     const response = await API.post(
  //       config.aws_cloud_logic_custom_name,
  //       Constants.UPDATE_MINI_SCORE,
  //       bodyParam
  //       // `${Constants.GET_MY_PROGRAMS}`, bodyParam,
  //     );
  //     console.log(response);
  //   } catch (error) {
  //     console.log("getCategoryError", error);
  //   }
  // }

  async function postQuizScorePost(score) {
    let objsr = {};
    let lcourseDetails = userDetails.curprgcou;

    const bodyParam = {
      body: {
        eid: userDetails.eid,
        scorejson: objsr,
        oid: config.aws_org_id,
        rtype: "put",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (lcourseDetails.bpid !== undefined) {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let date1 = date.getDate();
      let sdate = date1 + "." + month + "." + year;
      objsr.bpid = lcourseDetails.bpid;
      objsr.name = curObject.otitle;
      objsr.cdate = sdate;
      objsr.score = score;
      objsr[curObject.oid] = score;
      bodyParam.body.scorejson = objsr;
    }

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.POST_QUIZ,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      // alert('hello');
      console.log(response);
      getQuizScore(curObject);
      markComplete();
    } catch (error) {
      console.log("getCategoryError", error);
    }
  }

  function modallans() {
    const handleClosePop = () => {
      setOpen(false);
    };
    const showScore = () => {
      setOpen(false);
      setShowScore(true);
      console.log(formData.current);
      saveResponse(formData.current, curObject);
    };
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalConfirm}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h3 style={{ padding: "33px 33px 0px 63px", fontSize: "20px" }}>
                Are you sure you want to submit?
              </h3>
              <button
                style={{
                  position: "relative",
                  right: "40px",
                  top: "70px",
                  color: "#3372B5",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  fontSize: "15px",
                  height: "28px",
                  lineHeight: "13px",
                  float: "right",
                  width: "100px",
                  border: "2px solid #3372B5",
                }}
                onClick={() => {
                  showScore();
                  //setCurrentQuestion(0);
                }}
              >
                Confirm
              </button>
              <br />
              <button
                style={{
                  position: "relative",
                  right: "-28px",
                  top: "55px",
                  color: "lightcoral",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  fontSize: "15px",
                  height: "28px",
                  lineHeight: "13px",
                  float: "left",
                  width: "100px",
                  border: "2px solid lightcoral",
                }}
                onClick={() => {
                  setBtnClick(false);
                  //getQuiz(curObject);
                  /*
                  setScore(0);
                  setShowScore(false);
                  setCurrentQuestion(0); */
                  setScore(0);
                  setShowScore(false);
                  setCurrentQuestion(0);
                  handleClosePop();
                }}
              >
                Cancel
              </button>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }

  function setCheckbox(index, checked) {
    let newCheckboxes = [...checkboxes];
    if (newCheckboxes[index].checked == undefined) {
      newCheckboxes = produce(newCheckboxes, (draft) => {
        draft[index].checked = false;
      });
    }

    newCheckboxes = produce(newCheckboxes, (draft) => {
      draft[index].checked = checked;
    });
    setCheckboxes(newCheckboxes);

    let response = [];
    response.push(null);
    setQuizQuestion((currentQues) =>
      produce(currentQues, (v) => {
        v[currentQuestion].response = response;
        v[currentQuestion].iopts[index].checked = "true";
      })
    );

    let tmp = 0;
    for (let k = 0; k < checkboxes.length; k++) {
      if (
        checkboxes[k].checked !== undefined &&
        checkboxes[k].checked === true
      ) {
        tmp = 1;
        setCorrect(true);
      }
      if (tmp === 0 && k == checkboxes.length - 1) {
        setCorrect(undefined);
      }
    }

    checkboxes.forEach(function (element, index, array) {
      if (element.checked === true || element.checked === "true") {
        setQuizQuestion((currentQues) =>
          produce(currentQues, (v) => {
            v[currentQuestion].response.push(index);
          })
        );
      }
    });
    // alert(JSON.stringify(checkboxes))
    // alert(JSON.stringify(quizQuestion[currentQuestion]))
  }

  const handleNextQuestionClick = (anscount, scoreTemp) => {
    // alert(curObject.qtype)
    const nextQuestion = currentQuestion + 1;
    let qlength = quizQuestion.length - 1;
    let userAnscount = ansFcount;
    if (curObject.qtype == 2 || curObject.qtype == "2") {
      qlength = quizQuestion.length;
      userAnscount = anscount;
    }
    if (curObject.qtype == 1 || curObject.qtype == "1") {
      qlength = quizQuestion.length;
      userAnscount = anscount;
    }
    console.log("counts" + qlength);
    console.log("countsansFcount" + ansFcount);
    console.log("quizQuestion.length " + JSON.stringify(quizQuestion));

    if (nextQuestion < quizQuestion.length) {
      setCurrentQuestion(nextQuestion);
    } else if (curObject.qtype == 3) {
      setOpen(true);
    }
    // alert(JSON.stringify(quizQuestion))

    if (userAnscount === qlength) {
      setShowScore(true);
      console.log(formData.current);
      console.log("to check", formData.current, curObject);
      saveResponse(formData.current, curObject);
      if (qtype === 1 || qtype === "1") {
        let percent = (scoreTemp / quizQuestion.length) * 100;
        percent = Math.round(percent);
        console.log("percent===", percent);
        postQuizScorePost(percent);
      }
    }
  };

  const handlePrevQuestion = () => {
    console.log("prev", formData.current);
    setPrevData(true);
    saveResponse(formData.current, curObject);
    console.log("curr", currentQuestion);

    let res = [...quizQuestion];
    res[currentQuestion - 1].response =
      formData.current[currentQuestion - 1].response;
    setQuizQuestion(res);
    console.log(JSON.stringify(quizQuestion[currentQuestion]));

    const prevQuestion = currentQuestion - 1;
    /*  if (prevQuestion > 0) { */
    setCurrentQuestion(prevQuestion);
    /*   } */
  };

  const handleMiniDesOption = (correct, index) => {
    setQuizQuestion((currentQues) =>
      produce(currentQues, (v) => {
        v[currentQuestion].response = index;
      })
    );
    setCorrect(correct);
  };

  const handleAnswerOptionClick = (ansOpts, idx, anscount, scoreTemp) => {
    if (ansOpts.correct === "true" || ansOpts.correct === true) {
      if (curObject.qtype == 1 || curObject.qtype == 2) {
        setQuizQuestion((currentQues) =>
          produce(currentQues, (v) => {
            v[currentQuestion].lastanswer = "true";
          })
        );
        if (
          quizQuestion[currentQuestion].Qsubmit == undefined ||
          quizQuestion[currentQuestion].Qsubmit == "false"
        ) {
          setScore(score + 1);
          submitScore.current += 1;
          // alert('amupdating sore')
          console.log(
            "llll" + JSON.stringify(quizQuestion[currentQuestion].Qsubmit)
          );
        }
      } else {
        setScore(score + 1);
      }
    } else {
      if (curObject.qtype == 1 || curObject.qtype == 2) {
        if (
          quizQuestion[currentQuestion].lastanswer != undefined &&
          quizQuestion[currentQuestion].lastanswer == "true"
        ) {
          setQuizQuestion((currentQues) =>
            produce(currentQues, (v) => {
              v[currentQuestion].lastanswer = "false";
            })
          );
          setScore(score - 1);
          submitScore.current -= 1;
        }
        setQuizQuestion((currentQues) =>
          produce(currentQues, (v) => {
            v[currentQuestion].Qsubmit = "false";
          })
        );
      }
    }
    let answer = [...formativeAns];
    answer.push(idx);
    setFormativeAns(answer);

    console.log(typeof qtype);

    handleNextQuestionClick(anscount, scoreTemp);
  };
  const imgUrl =
    config.aws_org_id == "JSSAHER"
      ? `https://${
          Constants.DOMAIN
        }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${curObject.oid.substring(
          0,
          5
        )}/`
      : `https://${
          Constants.DOMAIN
        }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${curObject.oid.substring(
          0,
          6
        )}/`;
  // if (config.aws_org_id == "JSSAHER") {
  // imgUrl = `https://${
  //   Constants.DOMAIN
  // }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${curObject.oid.substring(
  //   0,
  //   5
  // )}/`;
  // } else {
  //    imgUrl = `https://${
  //     Constants.DOMAIN
  //   }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${curObject.oid.substring(
  //     0,
  //     6
  //   )}/`;
  // }

  const FILE_SIZE = 16000 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "application/pdf",
  ];
  const validationSchema = Yup.object().shape({
    text: Yup.string().required(
      <div style={{ fontSize: "12px" }}>"A text is required"</div>
    ),
    /* file: Yup.mixed() */
    /* .test(
        "fileSize",
        "File Size is too large",
        (value) => value.size <= FILE_SIZE
      ) */
    /* .test(
        "fileFormat",
        "Unsupported File Format",
        (value) => value == true && SUPPORTED_FORMATS.includes(value.type)
      ), */
  });

  function validateEmail(value) {
    let error;
    if (value) {
      console.log("val", value.type);
      if (
        value.type === "image/png" ||
        value.type === "image/jpg" ||
        value.type === "application/pdf" ||
        value.type === "image/jpeg"
      ) {
        error = "";
      } else {
        error = `*File not supported, please match requested type.`;
      }
      return error;
    }
  }

  function validateUsername(value) {
    let error;
    console.log("val1", value);

    if (value === "" || value.replace(/\s/g, "").length === 0) {
      error = "*A text is required";
    } else {
      error = "";
    }
    return error;
  }

  const getPreSignedUrl = async (value, obj) => {
    setSubmittingg(true);
    console.log("obj" + JSON.stringify(obj));
    console.log("val" + JSON.stringify(value));
    console.log(config.aws_org_id);

    // const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
    let fileName = new Date().getTime();

    let quest = handleAnswerOption(quizQuestion[currentQuestion]);
    quest.response = value.response;
    quest.filename = fileName + value.file.name;
    formData.current.push(quest);
    if (value.file) {
      const bodyParam = {
        body: {
          type: "minassign",
          filename: fileName + value.file.name,
          filetype: value.file.type,
          oid: config.aws_org_id,
          eid: userDetails.eid,
          quizid: obj.oid,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: jwttoken,
        },
      };
      //   alert(JSON.stringify(bodyParam.body))
      console.log(bodyParam);
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          Constants.GET_PRESIGNED_URL,
          bodyParam
        );

        // console.log(response);
        /* if (currentQuestion + 1 === quizQuestion.length) {
          setShowScore(true);
          console.log(formData.current);
          saveResponse(formData.current, curObject);
        } */
        handleNextQuestionClick();
        setSubmittingg(false);
        //alert("2");
        /* if (currentQuestion === quizQuestion.length) {
          setOpen(true);
        } */

        // console.log(response);

        fileUpload(value.file, response);
      } catch (error) {
        console.log("getCategoryError", error);
      }
    } else {
      handleNextQuestionClick();
      setSubmittingg(false);
    }
  };

  /*   function handleConfirm() {
    const nextQuestion1 = currentQuestion + 1;
    if (nextQuestion1 < quizQuestion.length) {
      setSettingnull(1);
    } else if (curObject.qtype === 3) {
      setPopup(true);
    }
  } */

  async function handleImageChange(event) {
    setSubmittingg(true);
    let pfileName = new Date().getTime();
    let fileUploaded = event.target.files[0];
    let filename = pfileName + event.target.files[0].name;
    let filetype = event.target.files[0].type;
    let quest = handleAnswerOption(quizQuestion[currentQuestion]);
    setQuizQuestion((currentQues) =>
      produce(currentQues, (v) => {
        v[currentQuestion].filename = filename;
        v[currentQuestion].uploadedfilename = event.target.files[0].name;
        v[currentQuestion].image = true;
        v[currentQuestion].imageurl = quest.imageurl;
      })
    );

    // formData.current.push(quizQuestion[currentQuestion]);
    // alert(JSON.stringify(quizQuestion[currentQuestion]))
    if (event.target.files[0]) {
      const bodyParam = {
        body: {
          type: "minassign",
          filename: filename,
          filetype: filetype,
          oid: config.aws_org_id,
          eid: userDetails.eid,
          quizid: curObject.oid,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      console.log(bodyParam);
      try {
        // const response = await API.post(
        //   config.aws_cloud_logic_custom_name,
        //   Constants.GET_PRESIGNED_URL,
        //   bodyParam
        // );
        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

        if (!allowedExtensions.exec(filename)) {
          // alert('invalid file type ')

          return false;
        }

        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          Constants.GET_PRESIGNED_URL,
          bodyParam
        );
        fileUpload(event.target.files[0], response);
        setSubmittingg(false);
      } catch (error) {
        console.log("getCategoryError", error);
      }
    }
  }

  async function fileUpload(file, url) {
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then((res) => {
        console.log("data", JSON.stringify(res));
        //setOpen(false);
        /* handleConfirm(); */
        //handleNextQuestionClick();
        //alert("3");
        //setOpen(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const TextInputComponent = ({ field, ...props }) => {
    console.log("dsds", field);
    console.log(props);
    const { errorMessage, touched } = props;
    const { name, value, onChange, onBlur } = field;
    // alert(JSON.stringify(field))

    console.log("testing", JSON.stringify(quizQuestion));

    return (
      <div className={classes.root}>
        <TextField
          fullWidth
          className={classes.textarea11}
          placeholder="Type your response here..."
          multiline
          rows={4}
          name={name}
          // value={(quizQuestion[currentQuestion].response == undefined
          //   || quizQuestion[currentQuestion].response.length == 0)?'':quizQuestion[currentQuestion].response}
          // value={value}
          // value={quizQuestion[currentQuestion].description || ''}
          variant="outlined"
          color="primary"
          error={touched && errorMessage ? true : false}
          label="Insert some text"
          helperText={touched && errorMessage ? errorMessage : undefined}
          onChange={onChange}
        />
      </div>
    );
  };

  function answerPrev() {
    console.log("curr", currentQuestion);
    const prevQuestion = currentQuestion - 1;
    setCurrentQuestion(prevQuestion);
  }

  function answerNext() {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizQuestion.length) {
      setCurrentQuestion(nextQuestion);

      if (
        quizQuestion[nextQuestion].atype == 3 ||
        quizQuestion[nextQuestion].atype == 5
      ) {
        setCorrect(undefined);
        setCheckboxes(quizQuestion[nextQuestion].iopts);
      }
    }
  }

  function quizView() {
    console.log("current", curObject);
    console.log("current", curObject.qtype);
    return (
      <div className="body" style={{ height: height - 400 }}>
        <div className="heading1">
          <h1
            style={{
              justifyContent: "start",
              color: "black",
            }}
          >
            {curObject.otitle}
          </h1>
        </div>

        {curObject.qtype == 0 ? (
          <FormativeFeedback
            Button={Button}
            imgUrl={imgUrl}
            produce={produce}
            getQuiz={getQuiz}
            btnClick={btnClick}
            setScore={setScore}
            curObject={curObject}
            answerPrev={answerPrev}
            ansFcount={ansFcount}
            answerNext={answerNext}
            handleCheck={handleCheck}
            setTryAgain={setTryAgain}
            qisLoading={qisLoading}
            setBtnClick={setBtnClick}
            handleClose={handleClose}
            setAnsFcount={setAnsFcount}
            markComplete={markComplete}
            setcurObject={setcurObject}
            setShowScore={setShowScore}
            quizQuestion={quizQuestion}
            answerReveal={answerReveal}
            setQIsLoading={setQIsLoading}
            feedbackButton={feedbackButton}
            currentQuestion={currentQuestion}
            setFormativeAns={setFormativeAns}
            setAnswerReveal={setAnswerReveal}
            setQuizQuestion={setQuizQuestion}
            setFeedbackButton={setFeedbackButton}
            setCurrentQuestion={setCurrentQuestion}
            getAssignmentDataPost={getAssignmentDataPost}
          />
        ) : (
          ""
        )}

        {curObject.qtype == 1 ? (
          <Summative
            score={score}
            imgUrl={imgUrl}
            Button={Button}
            produce={produce}
            getQuiz={getQuiz}
            sumScore={sumScore}
            btnClick={btnClick}
            summative={summative}
            curObject={curObject}
            showScore={showScore}
            ansFcount={ansFcount}
            setAnsFcount={setAnsFcount}
            answerPrev={answerPrev}
            answerNext={answerNext}
            handleClose={handleClose}
            qisLoading={qisLoading}
            setShowScore={setShowScore}
            setcurObject={setcurObject}
            quizQuestion={quizQuestion}
            formativeAns={formativeAns}
            setQIsLoading={setQIsLoading}
            setQuizQuestion={setQuizQuestion}
            currentQuestion={currentQuestion}
            setFeedbackButton={setFeedbackButton}
            handleAnswerOptionClick={handleAnswerOptionClick}
          />
        ) : (
          ""
        )}

        {curObject.qtype == 2 ? (
          <Formative
            score={score}
            cutOff={cutOff}
            Button={Button}
            imgUrl={imgUrl}
            getQuiz={getQuiz}
            setScore={setScore}
            btnClick={btnClick}
            tryAgain={tryAgain}
            produce={produce}
            curObject={curObject}
            ansFcount={ansFcount}
            setAnsFcount={setAnsFcount}
            showScore={showScore}
            setTryAgain={setTryAgain}
            setBtnClick={setBtnClick}
            handleClose={handleClose}
            qisLoading={qisLoading}
            setShowScore={setShowScore}
            setcurObject={setcurObject}
            answerReveal={answerReveal}
            quizQuestion={quizQuestion}
            answerNext={answerNext}
            answerPrev={answerPrev}
            formativeAns={formativeAns}
            markComplete={markComplete}
            setQuizQuestion={setQuizQuestion}
            setQIsLoading={setQIsLoading}
            setAnswerReveal={setAnswerReveal}
            setFormativeAns={setFormativeAns}
            currentQuestion={currentQuestion}
            setFeedbackButton={setFeedbackButton}
            setCurrentQuestion={setCurrentQuestion}
            getAssignmentDataPost={getAssignmentDataPost}
            handleAnswerOptionClick={handleAnswerOptionClick}
          />
        ) : (
          ""
        )}

        {curObject.qtype == 3 &&
        (curObject.miniassignmenttype == undefined ||
          (curObject.miniassignmenttype != undefined &&
            curObject.miniassignmenttype == 2)) &&
        curObject.userOP.edited != undefined &&
        curObject.userOP.edited == 1 ? (
          <EditMiniAssignment
            totalminiScore={totalminiScore}
            setEditmini={setEditmini}
            editmini={editmini}
            getAssignmentDataPost1={getAssignmentDataPost1}
            autoScorCal={autoScorCal}
            correct={correct}
            setCheckbox={setCheckbox}
            miniloader={miniloader}
            setMiniloader={setMiniloader}
            answerNext={answerNext}
            answerPrev={answerPrev}
            miniopen={miniopen}
            setMiniopen={setMiniopen}
            Button={Button}
            imgUrl={imgUrl}
            classes={classes}
            produce={produce}
            getQuiz={getQuiz}
            setScore={setScore}
            saveResponse={saveResponse}
            btnClick={btnClick}
            showScore={showScore}
            curObject={curObject}
            miniScore={miniScore}
            sMiniScore={sMiniScore}
            assignment={assignment}
            handleClose={handleClose}
            quizQuestion={quizQuestion}
            setShowScore={setShowScore}
            setQuizQuestion={setQuizQuestion}
            isSubmittingg={isSubmittingg}
            qisLoading={qisLoading}
            setQIsLoading={setQIsLoading}
            assignmentLoad={assignmentLoad}
            currentQuestion={currentQuestion}
            setAssignmentLoad={setAssignmentLoad}
            handleImageChange={handleImageChange}
            setCurrentQuestion={setCurrentQuestion}
            setAnsFcount={setAnsFcount}
            ansFcount={ansFcount}
            handleMiniDesOption={handleMiniDesOption}
          />
        ) : (
          curObject.qtype == 3 && (
            <MiniAssignment
              totalminiScore={totalminiScore}
              autoScorCal={autoScorCal}
              correct={correct}
              setCheckbox={setCheckbox}
              miniloader={miniloader}
              setMiniloader={setMiniloader}
              answerNext={answerNext}
              answerPrev={answerPrev}
              miniopen={miniopen}
              setMiniopen={setMiniopen}
              Button={Button}
              imgUrl={imgUrl}
              classes={classes}
              produce={produce}
              getQuiz={getQuiz}
              setScore={setScore}
              saveResponse={saveResponse}
              btnClick={btnClick}
              showScore={showScore}
              curObject={curObject}
              miniScore={miniScore}
              sMiniScore={sMiniScore}
              assignment={assignment}
              handleClose={handleClose}
              quizQuestion={quizQuestion}
              setShowScore={setShowScore}
              setQuizQuestion={setQuizQuestion}
              isSubmittingg={isSubmittingg}
              setQIsLoading={setQIsLoading}
              assignmentLoad={assignmentLoad}
              currentQuestion={currentQuestion}
              setAssignmentLoad={setAssignmentLoad}
              handleImageChange={handleImageChange}
              setCurrentQuestion={setCurrentQuestion}
              setAnsFcount={setAnsFcount}
              ansFcount={ansFcount}
              handleMiniDesOption={handleMiniDesOption}
            />
          )
        )}
      </div>
    );
  }

  const handleCheck = (i, idx) => {
    setQuizQuestion((currentQues) =>
      produce(currentQues, (v) => {
        v[currentQuestion].Qsubmit = "true";
        v[currentQuestion].iopts.forEach((opt) => {
          opt.Selected = false;
        });
      })
    );
    setQuizQuestion((currentQues) =>
      produce(currentQues, (v) => {
        v[currentQuestion].iopts[idx].Selected = true;
        v[currentQuestion].iopts[idx].Myselection = "true";
      })
    );
    setFeedbackButton({ ...feedbackButton, disableSubmit: false });
  };

  return (
    <div style={{ height: "100%", width: "100%", overflowY: "auto" }}>
      <div style={{ width: "100%", minHeight: "100px" }}>
        {modallans()}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              fontSize: "18px",
              paddingLeft: "2%",
              paddingTop: "1%",
              fontWeight: "500",
            }}
          >
            {curObject.otitle}
          </div>
          <span
            style={{
              float: "right",
              cursor: "pointer",
              color: "black",
              fontSize: "26px",
              paddingRight: "20px",
            }}
            onClick={() => {
              handleClose();
              setTryAgain(false);
              setFormativeAns([]);
              getQuiz(curObject);
            }}
          >
            x
          </span>
        </div>
      </div>
      {/* <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <UserHeader /> */}

      {loaded == true ? (
        <div style={{ height: "100%", width: "100%" }}>
          <div
            ref={full}
            style={{
              height: height - 300,
              width: width - 300,
              padding: "2%",
              position: "relative",
            }}
          >
            {curObject.nenabled == true || curObject.nenabled == "true"
              ? renderObjects()
              : unitLockedView()}
            {/* {JSON.stringify(curObject)} */}
            {/* <div>
      width: {width} ~ height: {height}
    </div> */}
            {curObject.otype === "Interactivity" ||
            curObject.otype === "interactivity" ||
            curObject.otype === "pdf" ? (
              <IconButton
                style={{ position: "absolute", bottom: "28px", right: "50px" }}
                onClick={() => {
                  toggleFullScreen();
                  onFullScreen();
                }}
                className={classes.bottomIcons}
              >
                {fullscreen ? (
                  <Tooltip
                    title={
                      <p style={{ fontSize: "13px" }}>
                        Click here for full-screen mode
                      </p>
                    }
                    placement="top"
                  >
                    <Fullscreen
                      fontSize="large"
                      className={classes.volumeButton}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      <p style={{ fontSize: "13px" }}>
                        Click here for exit full-screen mode
                      </p>
                    }
                    placement="top"
                  >
                    <FullscreenExitIcon
                      fontSize="large"
                      className={classes.volumeButtonExit}
                      style={{ bottom: "200px" }}
                    />
                  </Tooltip>
                )}
              </IconButton>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "150px",
              width: "100%",
              padding: "2%",
            }}
          >
            {curObject.nenabled == true || curObject.nenabled == "true"
              ? renderButtons()
              : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CoursePlayer;
