import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData, awsSignOut } from "../../redux/auth/authSlice";
import UserHeader from "../Header/UserHeader/UserHeader";
import ProgramCard from "../../modules/ProgramCard/ProgramCard";
import MyProgStyle from "./MyPrograms.module.scss";
import { FaBars } from "react-icons/fa";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
const MyPrograms = ({ handleToggleSidebar }) => {
  const [programData, setprogramData] = useState(true);

  const userDetails = useSelector(authData);
  const dispatch = useDispatch();

  useEffect(() => {
    // if(userDetails.data == undefined){
    //   getUserProgressDetails();
    // } else {
    //   setprogramData(userDetails.data.progress);
    // }
    //setprogramData(userDetails.data.bpdata);
    //alert(JSON.stringify(userDetails));
  }, []);

  async function getUserProgressDetails() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USER_PROGRESS,
        bodyParam
      );
      userDetails.data = response;
      setprogramData(userDetails.data.progress);
      dispatch(awsSignIn(userDetails));

      //getEvents(UserProgressDetailsJSON);
      //alert("HI "+JSON.stringify(response));
    } catch (error) {
      console.error(error);
    }
  }

  function programcardmap(programData) {
    // if(userDetails.data == undefined){
    //   return (
    //     <div>

    //     </div>
    //   );
    // }
    // let progress = userDetails.data.progress;

    let data = [];
    if (userDetails.data != undefined) {
      if (userDetails.data.bpdata != undefined) {
        data = [...userDetails.data.bpdata];
      }
    }

    return (
      <div>
        <h3
          style={{
            color: "black",
            marginLeft: "8px",
            fontSize: "20px",
            fontWeight: "500",
            paddingBottom: "20px",
          }}
        >
          Choose a program{" "}
        </h3>
        {data.map((Value, index, array) => {
          return (
            <div style={{ margin: "0 0 03.5rem 0" }}>
              <ProgramCard Value={Value} />
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <main>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <UserHeader />
      <div style={{ marginTop: "3rem", padding: "0 1rem" }}>
        <div style={{ overflow: "auto", height: "88vh" }}>
          {programData == false ? (
            <>
              <div>
                <h1>Loading....</h1>
              </div>
            </>
          ) : (
            programcardmap()
          )}
        </div>
      </div>
    </main>
  );
};

export default MyPrograms;
