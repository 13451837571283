import React, { Component, useState, useEffect } from "react";
import { Tab } from "semantic-ui-react";
import Rating from "@material-ui/lab/Rating";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from "react-redux";

import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Card from "@material-ui/core/Card";
import swal from "sweetalert";
import { Image } from "semantic-ui-react";
import { setIL, resetIL, iLData } from "../../redux/auth/iLearnSlice";

import InstructorsCard from "../../modules/Cards/Instructors/InstructorsCard";

import "../../modules/Tabs/Tabs.scss";
import AppHeader from "../Header/AppHeader";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { API } from "aws-amplify";
import Footer from "../Footer/Footer";
import { Routes, Route, useParams } from 'react-router-dom';

import bio from "../../assets/images/P1 - PG Diploma in bioinformatics.jpg";
import logo from "../../assets/images/africalogo.png";
import EmailIcon from "../../assets/svgjs/email";


import Rupee from "../../assets/svgjs/Rupee";
import PlaceholderParagraph from "../../modules/Placeholder/PlaceholderParagraph";

import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import ClockOrange from "../../assets/svgjs/ClockOrange";

import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import myProgPreLoginStyle from "./MyProgramsPreLogin.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiAccordionDetails-root": {
      display: "block",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    height: "400px",
    width: "600px",
    borderRadius: "4px",
    border: "none",
    padding: theme.spacing(2, 4, 3),
  },
  textBox: {
    "& .MuiInputBase-input": {
      fontSize: "medium ",
      paddingLeft: "34px",
    },
  },
  tabHeader: {
    '& > div': {
      '@media (max-width: 520px)': {
        overflowX: "auto",
        overflowY: "hidden",
      },
    },
  },
}));

function MyProgramsPreLogin() {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const [programsJSON, setProgramsJSON] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ils, setIls] = useState(false);
  const [bpid, setBpid] = useState("");
  const [open, setOpen] = useState(false);
  const [regEmail, setRegEmail] = useState("");
  const [spin, setSpin] = useState(false);
  const [value, setValue] = React.useState(4);
  const { pid, il } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const idata = useSelector(iLData);
  const navigate = useNavigate();


  useEffect(() => {

    getProgBpid();
  }, []);

  useEffect(() => {
    if (il == "1") {
      setIls(true);
    } else {
      setIls(false);
    }
  }, [il]);



  const panes = [
    {
      menuItem: "Overview",
      render: () => (
        <Tab.Pane
          className={myProgPreLoginStyle.overview}
          style={{ paddingBottom: "8%" }}
        >
          {isLoading ? (
            <PlaceholderParagraph />
          ) : (
            <p
              className={myProgPreLoginStyle.p_text}
              dangerouslySetInnerHTML={{ __html: programsJSON.poverview }}
            ></p>
          )}
          <div></div>
          <div>
            <h2
              style={{ fontSize: "18px" }}
              className={myProgPreLoginStyle.h2_margin}
            >
              Program features
            </h2>
            {isLoading ? (
              <div style={{ width: "100%", overflow: "hidden" }}>
                <PlaceholderParagraph />
              </div>
            ) : (
              <p
                className={myProgPreLoginStyle.p_text}
                dangerouslySetInnerHTML={{ __html: programsJSON.pfeatures }}
              ></p>
            )}
          </div>
          <hr />
          <div>
            <h2
              style={{ fontSize: "18px" }}
              className={myProgPreLoginStyle.h2_margin}
            >
              Program outcomes
            </h2>
            {isLoading ? (
              <PlaceholderParagraph />
            ) : (
              <p
                className={myProgPreLoginStyle.p_text}
                dangerouslySetInnerHTML={{ __html: programsJSON.poutcomes }}
              ></p>
            )}
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Instructors",
      render: () => (
        <Tab.Pane>
          <div>
            <div>
              <div className="instructors">
                {programsJSON.pinstructors.map(
                  (item, idx) => (
                    <div>
                      <div className="instructors__h1">
                        <h2
                          style={{
                            marginLeft: "20px",
                            marginTop: "20px",
                            fontSize: "18px",
                          }}
                        >
                          {item.title}
                        </h2>
                      </div>
                      <div>
                        <div
                          className="card-container"
                          style={{ float: "left", marginLeft: "50px" }}
                        >
                          {item.value.map(
                            ({ name, designation, org, pic }, idx) => (
                              <InstructorsCard
                                key={idx}
                                name={name}
                                designation={designation}
                                org={org}
                                pic={pic}
                                pid={programsJSON.pid}
                              />
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Curriculum",
      render: () => (
        <Tab.Pane>
          {programsJSON
            ? programsJSON.pcurriculum.map(
              ({ tlabel, tduration, ttitle, tunits, tid }) => (
                <div className={myProgPreLoginStyle.pcurriculum}>
                  <div className={myProgPreLoginStyle.courses_container}>
                    <div className={myProgPreLoginStyle.ID_container}>
                      <div
                        className={myProgPreLoginStyle.image_container}
                        style={{
                          backgroundImage: `url('https://${Constants.DOMAIN
                            }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${tid}.png')`,
                        }}
                      ></div>
                      <div className={myProgPreLoginStyle.details_container}>
                        <div>
                          <h3
                            style={{ fontSize: "15px", marginBottom: "10px" }}
                          >
                            {tlabel}
                          </h3>
                          <h2
                            style={{ fontSize: "15px", fontWeight: "bold" }}
                          >
                            {ttitle}
                          </h2>
                        </div>
                        <div>
                          <p style={{ marginTop: "-12px" }}>
                            <span>
                              <ClockOrange
                                className={myProgPreLoginStyle.clock_size__s}
                                cls1={myProgPreLoginStyle.cls1_s}
                                cls2={myProgPreLoginStyle.cls2_s}
                              />
                            </span>
                            <span style={{ fontSize: "small" }}>
                              {tduration} Weeks
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {tunits.map((units) => (
                      <div>
                        <p className={myProgPreLoginStyle.tunits}>{units}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )
            )
            : null}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "FAQs",
      render: () => (
        <Tab.Pane>
          <div style={{ paddingBottom: "22%" }}>
            {programsJSON
              ? programsJSON.pfaq.map(({ title, questions }, idx) => (
                <div className={classes.root} key={idx}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        <h2 style={{ fontSize: "15px", fontWeight: "bold" }}>
                          {title}
                        </h2>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {questions.map(({ ques, ans }, id) => (
                        <div className={classes.root} key={id}>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <h3 style={{ fontSize: "15px" }}>{ques}</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography style={{ fontSize: "13px" }}>
                                {ans}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))
              : null}
          </div>
        </Tab.Pane>
      ),
    },
  ];
  console.log("aaaa", localStorage.getItem("bpid"));
  console.log("aaaa", localStorage.getItem("pid"));
  const handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);
  async function getProgBpid() {

    setIsLoading(true);
    // alert(il.toString(),pid);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        pid: pid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.getBatchID,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      let obj = {};
      if (idata != 0 && pid && il) {
        obj = {
          ...idata,
          pid: pid,
          il: il,
          mpbpid: response
        };
        dispatch(setIL(obj));
      } else if (pid && il) {
        obj = {
          pid: pid,
          il: il,
          mpbpid: response
        };
        dispatch(setIL(obj));
      }
      setBpid(response);
      getProgramJson(response);
    } catch (error) {
      console.log("getCategoryError", error);
    }

  }

  async function getProgramJson(bpid) {


    // alert(il.toString(),pid);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: bpid,
        pid: pid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PROGRAM,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      const programsJSON = response;
      console.log(programsJSON);
      setProgramsJSON(programsJSON);

      setIsLoading(false);
    } catch (error) {
      console.log("getCategoryError", error);
    }
    console.log("Mount");
  }

  async function applyEvbabApplication() {
    // localStorage.setItem("eVBAB", false);
    setSpin(true);
    const bodyParam = {
      body: {
        emailid: regEmail,
        // emailid: "nisarga@enhanzed.com"
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GCP_RESPONSE,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );

      let json = JSON.stringify(response);

      let presonse = json.toString();

      presonse = JSON.parse(presonse);
      setSpin(false);
      // alert(presonse);
      try {
        presonse = presonse?.replace(")]}'", "");
        presonse = JSON.parse(presonse);
        console.log(presonse)
        var msg = JSON.parse(presonse.payload);
        console.log(msg)
        if (msg.message != "Successful") {
          alert(
            "To apply for scholarship, you need to register in the  https://ilearn.gov.in iLearn portal first"
          );
          // swal({
          //     html:
          //     '<p style="color:#484848">To apply for scholarship,<br> you need to register in the  <a href="https://ilearn.gov.in" target="_blank">iLearn portal</a> first</p>',
          //     // text: "To apply for scholarship, you need to register in the iLearn portal first",
          // type: "warning", width: '400px',
          // showConfirmButton: true, confirmButtonText: 'Ok', confirmButtonColor: "#E77C2D" });
          // $scope.drpscholarship.drpval = 'No';
        } else {
          let sdata = {};
          if (idata != 0) {
            sdata = { ...idata }
          }
          sdata.evbab = true;
          sdata.evbabdata = msg;
          sdata.pid = programsJSON.pid;
          sdata.bpid = programsJSON.bpid;
          sdata.pname = programsJSON.pname;
          sdata.applicationid = undefined;
          sdata.pstatus = programsJSON.pstatus;
          sdata.apply = true;

          dispatch(setIL(sdata));

          navigate("/application");
        }
      } catch (err) {
        swal(
          "To apply for scholarship, you need to register in the  https://ilearn.gov.in iLearn portal first"
        );
      }
      // getprofileresponse(ptoken.token);
    } catch (error) {
      console.log("getCategoryError", error);
    }
    console.log("Mount");
  }

  function ILe() {
    return (
      <>
        {ils == true ?
          <Typography component="div" key="h2" variant="h2">

            <h1 style={{ fontSize: "20px", marginBottom: "20px" }}>
              <strong> Welcome to JSSAHER - eVidyaBharati Admission Portal</strong>
            </h1>

          </Typography> : ""}
        {ils == true ?
          <div style={{ padding: "12px", textAlign: "justify", border: "1px solid black", marginBottom: "12px" }}>
            The Government of India offers 100% Scholarship to students from partner African Countries through e-VidyBharati and e-ArogyaBharati (e-VBAB) Network Project to pursue ONLINE Undergraduate, Postgrauate, Diploma and Certificate programmes from various reputed Indian Universities/ Institutes in various disciplines including computer application, business administration and commerce, healthcare, tourism, humanities and arts etc.
          </div> : null}
        {ils == true ?
          (
            <div style={{ fontSize: "16px", marginBottom: "8px" }}>
              Need Instructions for applying :-
              <a href={`https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/pdf/ilearn/il.pdf`}
                target="_blank"
              > Click Here</a>
            </div>
          )
          : null}
      </>
    );
  }

  function ApplB() {
    // let t = searchParams.get("t");
    if (ils == true) {
      return (
        <div className={myProgPreLoginStyle.align_self}>
          <button
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              width: "100px",
              borderRadius: "6px",
              height: "30px",
            }}
            className={myProgPreLoginStyle.btn_color}
            onClick={() => {
              setOpen(true);
            }}
          >
            Apply
          </button>
        </div>
      );
    } else {
      return null;
    }
  }

  function selectType() {
    const handleClose = () => {
      setOpen(false);
    };
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <span
                style={{ float: "right", cursor: "pointer", fontSize: "16px", fontWeight: "bold" }}
                onClick={() => {
                  handleClose();
                }}
              >
                x
              </span>
              <div style={{ display: "flex" }}>
                <Image
                  size="small"
                  src={`https://${Constants.DOMAIN
                    }/${config.aws_org_id.toLocaleLowerCase()}-resources/images/org-images/logo.jpg`}
                  style={{
                    marginLeft: "106px",
                    marginTop: "5px",
                    // marginBottom: "50px",
                    width: "200px",
                  }}
                />
                <Image
                  size="small"
                  src={logo}
                  style={{

                    marginTop: "5px",
                    // marginBottom: "50px",
                    height: "100px",
                    width: "150px",
                  }}
                />
              </div>
              {/* <label
                  style={{ marginLeft: "80px", marginBottom: "-80px" }}
                  className={myProgPreLoginStyle.labelForIssue}
                >
                  {regEmailTextIssue}
                </label> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  marginTop: "20px"
                }}
              >
                <EmailIcon className={myProgPreLoginStyle.emailIc} />
                <TextField
                  style={{
                    width: "360px",
                  }}
                  className={classes.textBox}
                  type="text"
                  required
                  id="standard-required"
                  placeholder="Email"
                  value={regEmail}
                  onChange={(e) => setRegEmail(e.target.value)}
                  onClick={() => {
                    // resetEmailPassIssueText(0);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      applyEvbabApplication();
                    }
                  }}
                />
              </div>

              <input
                type="button"
                style={{
                  backgroundColor: "white",
                  marginLeft: "220px",
                  marginTop: "20px",
                  marginBottom: "10px",
                  color: "#3372B5",
                  height: "30px",
                  width: "80px",
                  borderRadius: "3px",
                  borderColor: "#3372B5",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
                onClick={() => {
                  applyEvbabApplication();
                }}
                value="Verify"
              />
              <div style={{ padding: "12px", textAlign: "justify", border: "1px solid black" }}>
                The Government of India offers 100% Scholarship to students from partner African Countries through e-VidyBharati and e-ArogyaBharati (e-VBAB) Network Project to pursue ONLINE Undergraduate, Postgrauate, Diploma and Certificate programmes from various reputed Indian Universities/ Institutes in various disciplines including computer application, business administration and commerce, healthcare, tourism, humanities and arts etc.
              </div>
            </div>

          </Fade>
        </Modal>
      </>
    );
  }
  function loginClick() {
    let sdata = {};
    if (idata != 0) {
      sdata = { ...idata }
    }
    sdata.programjson = programsJSON;
  
    dispatch(setIL(sdata));
    navigate('/marApplicationPre');
  }
  return (
    <div>
      {isLoading === true ? <div style={{
        position: "fixed",
        backgroundColor: "#000000a1",
        width: "100vw",
        height: "100vh",
        display: "flex",
        top: 0,
        left: 0,
        zIndex: 2000,
      }}><CircularProgress style={{ position: "fixed", top: "48%", left: "48%", background: "transparent", zIndex: "2001" }} /> </div> : null}
      {spin === true ? <div className={{
        position: "fixed",
        backgroundColor: "#000000a1",
        width: "100vw",
        height: "100vh",
        display: "flex",
        top: "0",
        left: "0",
        zIndex: "2000",
      }}><CircularProgress style={{ position: "fixed", top: "48%", left: "48%", background: "transparent", zIndex: "2001" }} /> </div> : null}
      {selectType()}
      <div style={{ marginTop: "10rem" }}>
        <AppHeader mpbpid={bpid} />
      </div>

      <div className={myProgPreLoginStyle.overview}>
        <div className={myProgPreLoginStyle.overview__h1}>
          <Link to={`/`}>
            <p
              style={{
                color: "#01498e",
                fontSize: "small",
                marginTop: "-20px",
                marginBottom: "20px",
              }}
            >{`< Back To Home`}</p>
          </Link>

          <ILe />
          <Typography component="div" key="h2" variant="h2">
            {isLoading ? (
              <Skeleton />
            ) : (
              <h1 style={{ fontSize: "20px", marginBottom: "20px" }}>
                <strong> {programsJSON.pname}</strong> - Online program
              </h1>
            )}
          </Typography>
        </div>
        <div className={myProgPreLoginStyle.overview__card}>
          <div className={myProgPreLoginStyle.overview__cardContent}>
            <div className={myProgPreLoginStyle.content}>
              <div className={myProgPreLoginStyle.content__details}>
                <div className={myProgPreLoginStyle.align_self}>
                  {/*  <Rating name="size-small" value={value} readOnly /> */}

                </div>
                <div className={myProgPreLoginStyle.items}>
                  <div>
                    <strong>
                      <span>
                        <ClockOrange
                          className={myProgPreLoginStyle.clock_size}
                          cls1={myProgPreLoginStyle.cls_1}
                          cls2={myProgPreLoginStyle.cls_2}
                        />
                      </span>
                      {isLoading ? <Skeleton /> : programsJSON.pinfo.duration}
                    </strong>
                  </div>
                  <div>
                    Format:{" "}
                    <strong>
                      {isLoading ? <Skeleton /> : programsJSON.pinfo.format}
                    </strong>
                  </div>
                </div>
                <div className={myProgPreLoginStyle.items}>
                  <div>
                    <strong>
                      <span>
                        {/* <Rupee className={myProgPreLoginStyle.rupee_size} /> */}
                      </span>{" "}
                      {isLoading ? <Skeleton /> : ""
                        // programsJSON.pinfo.price
                      }
                    </strong>
                  </div>
                  <div className={myProgPreLoginStyle.align_credits}>
                    Credits:{" "}
                    <strong>
                      {" "}
                      {isLoading ? <Skeleton /> : programsJSON.pinfo.credits}
                    </strong>
                  </div>
                </div>
                <ApplB />
                <div style={{ display: 'flex', justifyContent: 'center' }}><input
                  type="button"
                  style={{
                    backgroundColor: "#3372B5",
                    marginLeft: "220px",
                    color: "white",
                    height: "34px",
                    width: "110px",
                    borderRadius: "3px",
                    border: "none",
                    fontWeight: "bold",
                    fontSize: "15px",
                    marginTop: "20px",
                  }}
                  onClick={() => {
                    loginClick();
                  }}
                  value="Apply Now"
                /></div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Skeleton variant="rect" width="100%">
              <div style={{ paddingTop: "57%" }} />
            </Skeleton>
          ) : (
            <div className={myProgPreLoginStyle.overview__cardImage}>
              <img
                style={{
                  backgroundImage: `url('https://${Constants.DOMAIN
                    }/${config.aws_org_id.toLowerCase()}-resources/images/program-images/${programsJSON.pid
                    }.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
                /* src={bio} */ alt=""
              />
            </div>
          )}
        </div>
      </div>

      {/* <div className={myProgPreLoginStyle.tabMargin}>
        <Tab
          panes={panes}
          activeIndex={activeIndex}
          onTabChange={handleTabChange}
          className={classes.tabHeader}
        />
      </div> */}
      <Footer />
    </div>
  );
}

// class MyProgramsPreLogin extends Component {
//   state = {
//     activeIndex: 0,
//     programsJSON: [],
//     isLoading: true,
//   };

//   async componentDidMount() {

//   }

//   render() {
//     const { activeIndex, programsJSON, isLoading } = this.state;
//     const classes = this.useStyles;
//     console.log("render");

//     return (

//     );
//   }
// }

export default MyProgramsPreLogin;