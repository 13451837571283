import "./Formative.scss";
import Done from "../../../assets/svgjs/Done";
import Reject from "../../../assets/svgjs/Reject";
import React, { useState, useEffect, useRef } from "react";
import useWindowDimensions from "../../../modules/Window/Window";
import CircularProgress from "@material-ui/core/CircularProgress";

const Summative = (props) => {
let {
    score,
    Button,
    imgUrl,
    getQuiz,
    produce,
    btnClick,
    sumScore,
    summative,
    showScore,
    curObject,
    answerNext,
    answerPrev,
    qisLoading,
    ansFcount,
    setAnsFcount,
    quizQuestion,
    setShowScore,
    setQIsLoading,
    currentQuestion,
    setQuizQuestion,
    setFeedbackButton,
    handleAnswerOptionClick
} = props;

const summativeCount = useRef([]);
const answerCount = useRef({});
const { height, width } = useWindowDimensions();
useEffect(() => {
    console.log('Mounted Summative Quize');    
}, []);

return (
     <div className="body" style={{ height: (btnClick || showScore) ? height - 400 : '' }}>
        {btnClick ?(
            <div className="app1">
                {showScore ? (
                <div className="score-section">
                    <div>
                        You scored {score} out of {quizQuestion.length}  
                    </div>                  
                </div>
                ) 
                :(
                    <div className="scrollQuiz">
                        <div className="question-section">
                            <div className="question-count">
                                <span>Question{currentQuestion + 1}</span>/
                                {quizQuestion.length}
                            </div>
                            <div
                                className="question-text"
                                dangerouslySetInnerHTML={{
                                __html: quizQuestion[currentQuestion].istem,
                                }}>
                            </div>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: `${
                            quizQuestion[currentQuestion].imageurl ? "4fr": "1fr"} `,
                            gridGap: "2rem",
                          }}
                        >
                        <center>
                            <div style={{ width: "270px" }}>
                                {quizQuestion[currentQuestion].imageurl ? (
                                <div className="img_container">
                                    <img src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`} alt="item"/>
                                </div>
                                ) : null}
                            </div>
                        </center>
                        <div>
                        {quizQuestion[currentQuestion].iopts.map(
                            (answerOption, idx) => (
                            <button
                                key={"a" + idx}
                                // className="buttonQuiz"
                                className={`buttonQuiz`}
                                data-id={`${
                                answerOption.correct == true
                                    ? answerOption.correct
                                    : null
                                }`}
                                style={{ borderColor: answerOption.Myselection == 'true'?'#0f80a4' : '' }}

                                onClick={() => {
                                if(quizQuestion[currentQuestion].Qsubmit == undefined){
                                    setAnsFcount(ansFcount+1);
                                }
                                setQuizQuestion((currentQues) =>
                                    produce(currentQues, (v) => {
                                        v[currentQuestion].Qsubmit = 'true';
                                        v[currentQuestion].iopts.forEach((opt) => {
                                            opt.Myselection = 'false';
                                        });
                                        v[currentQuestion].iopts[idx].Myselection = 'true';
                                    })
                                );
                                if(!summativeCount.current.includes(currentQuestion)){
                                    summativeCount.current.push(currentQuestion);
                                }
                                if (answerOption.correct == true || answerOption.correct == "true") {
                                    answerCount.current[`a${currentQuestion}`] = 1;
                                } else {
                                    answerCount.current[`a${currentQuestion}`] = 0;
                                }
                                let scoreTemp = 0;
                                for (const key in answerCount.current) {
                                    if (Object.hasOwnProperty.call(answerCount.current, key)) {
                                        const element = answerCount.current[key];
                                        scoreTemp = scoreTemp + element;
                                    }
                                }
                                console.log("scoreTemp "+scoreTemp);
                                handleAnswerOptionClick(answerOption, idx, summativeCount.current.length, scoreTemp);
                                console.log("ans", answerOption, idx);
                                }}>
                                {answerOption.content}
                            </button>
                            )
                        )}
                         <div style={{padding:'3%'}}>
                            {currentQuestion > 0 ? (
                                <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                className="btn-size"
                                onClick={() => answerPrev()}
                                style={{ fontSize: "12px" }}>
                                Prev
                                </Button>
                            ) : null}
                            {currentQuestion + 1 === quizQuestion.length ? (
                                <div></div>
                            ) : (
                                <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                className="btn-siz"
                                onClick={() => answerNext()}
                                style={{ float: "right", fontSize: "12px" }}
                                >
                                Next
                                </Button>
                            )}
                        </div>
                        </div>
                    </div>
                </div>
                )}
            </div>
        ): (
            <div style={{ fontSize: "14px" }}>
                {qisLoading ? (
                <CircularProgress color="default" size={30} />
                ) :(
                <div>
                    {summative ? (
                        <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                        }}
                        >
                        <h1
                            style={{
                            color: "#000",
                            textAlign: "center",
                            margin: "2.5rem 0",
                            boxShadow: "0 0 0 5px #e35f14",
                            borderRadius: "100%",
                            height: "120px",
                            width: "120px",
                            position: "relative",
                            }}>
                            <span
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}>
                            {(sumScore > 100 || sumScore > "100") ? 100 : sumScore } %
                            </span>
                        </h1>
                    </div>
                    ) : (
                    <button
                        className="btnQuizLD"
                        onClick={() => {
                        getQuiz(curObject);
                        setShowScore(false);
                        setQIsLoading(true);
                        setFeedbackButton({
                            disableSubmit: true,
                            hideNext: true,
                            hideSubmit: false,
                            submitted: false,
                        });
                        }}>Start Quiz
                    </button>
                    )}
                </div>
                )}
            </div>
        )}
    </div>
    );
}

export default Summative;
